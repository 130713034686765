import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createEmployee, updateEmployee } from "../../redux/Employee/employeeSlice";
import { FileUploader } from "react-drag-drop-files";
import { useParams } from "react-router-dom";
import { createCase, getSinglecase, updateIndividualCase, validateEmail, validateMobile } from "../../redux/Cases/CaseSlice";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const UpdateCase = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const { totalCars, loginData } = useSelector((store) => store.auth);
    const { employeeList } = useSelector((store) => store.employee);
    const [requesterName, setRequesterName] = useState("");
    const [requesterNameError, setRequesterNameError] = useState("");
    const [requesterBranch, setRequesterBranch] = useState("");
    const [requesterBranchError, setRequesterBranchError] = useState("");
    const [requesterPhone, setRequesterPhone] = useState("");
    const [requesterPhoneError, setRequesterPhoneError] = useState("");
    const [email, setEmail] = useState("");
    const [Cnfemail, setCnfEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [emailExistError, setEmailExistError] = useState("");
    const [mobile, setMobile] = useState("");
    const [mobileCnf, setMobileCnf] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [mobileExistError, setMobileExistError] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [address, setAddress] = useState("")
    const [addressError, setAddressError] = useState("")
    const [state, setState] = useState("")
    const [stateError, setStateError] = useState("")
    const [city, setCity] = useState("")
    const [cityError, setCityError] = useState("")
    const [productBrand, setProductBrand] = useState("")
    const [productBrandError, setProductBrandError] = useState("")
    const [model, setModel] = useState("")
    const [ModelError, setModelError] = useState("")
    const [variant, setVariant] = useState("")
    const [variantError, setVariantError] = useState("")
    const [regdNo, setRegdNo] = useState("")
    const [regdNoError, setRegdNoError] = useState("")
    const [clientList, setClientList] = useState([])
    const [client, setClient] = useState("")
    const [customerList, setCustomerList] = useState([])
    const [customer, setCustomer] = useState("")
    const [executiveList, setExecutiveList] = useState([])
    const [executive, setExecutive] = useState("")
    const [associateList, setAssociateList] = useState([])
    const [associate, setAssociate] = useState("")
    const [agentList, setAgentList] = useState([])
    const [agent, setAgent] = useState("")
    const [uniqueNames, setUniqueNames] = useState([]);
    const [uniqueType, setUniqueType] = useState([]);
    const [caseId, setCaseId] = useState("")

    const [role, setRole] = useState("")

    const [btnPress, setbtnPress] = useState(false);

    useEffect(() => {
        const uniqueNamesArray = [];
        const uniquesTypesArray = []
        const flattenedArray = totalCars.reduce((acc, currentArray) => {
            return acc.concat(currentArray);
        }, []);
        flattenedArray.forEach(car => {
            if (!uniqueNamesArray.includes(car.Make)) {
                uniqueNamesArray.push(car.Make);
            }
            if (!uniquesTypesArray.includes(car.Type)) {
                uniquesTypesArray.push(car.Type)
            }
        });
        setUniqueNames(uniqueNamesArray);
        setUniqueType(uniquesTypesArray)
    }, [totalCars]);

    const selectProductBrandChange = (e) => {
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setProductBrand(text);
    };
    const selectProductModelChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setModel(text);
    };
    const selectProductVariantChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVariant(text);
    };

    const selectExecutiveChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const executive = {
            name: e.nativeEvent.target[index].text,
            id: e.nativeEvent.target[index].value
        }
        setExecutive(executive);
    };
    const selectAssociateChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const associate = {
            name: e.nativeEvent.target[index].text,
            id: e.nativeEvent.target[index].value
        }
        setAssociate(associate);
    };
    const selectAgentChange = (e) => {
        const index = e.nativeEvent.target.selectedIndex;
        const agent = {
            name: e.nativeEvent.target[index].text,
            id: e.nativeEvent.target[index].value
        }
        setAgent(agent);
    };
    const selectClientChange = (e) => {
        const index = e.nativeEvent.target.selectedIndex;
        const client = {
            name: e.nativeEvent.target[index].text,
            id: e.nativeEvent.target[index].value
        }
        setClient(client);
    };
    const selectCustomerChange = (e) => {
        const index = e.nativeEvent.target.selectedIndex;
        const client = {
            name: e.nativeEvent.target[index].text,
            id: e.nativeEvent.target[index].value
        }
        setCustomer(client);
    };
    useEffect(() => {
        const getCaseDetail = async () => {
            const getCase = await dispatch(getSinglecase(params._id));
            if (getCase.payload.success) {
                setRequesterName(getCase.payload.case.requesterName)
                setRequesterPhone(getCase.payload.case.requesterPhone)
                setRequesterBranch(getCase.payload.case.requesterBranch)
                setName(getCase.payload.case.name);
                setMobile(getCase.payload.case.mobile);
                setMobileCnf(getCase.payload.case.mobile);
                setCnfEmail(getCase.payload.case.email);
                setEmail(getCase.payload.case.email);
                setAddress(getCase.payload.case.address);
                setState(getCase.payload.case.state);
                setCity(getCase.payload.case.city);
                setProductBrand(getCase.payload.case.productBrand);
                setModel(getCase.payload.case.model);
                setVariant(getCase.payload.case.variant);
                setRegdNo(getCase.payload.case.regdNo);
                setProductBrand(getCase.payload.case.brand)
                setCaseId(getCase.payload.case.caseId)
            }
        }
        getCaseDetail()
    }, [])
    useEffect(() => {
        if (btnPress === true) {
            if (requesterName === '') {
                setRequesterNameError('Please enter valuation purpose')
            } else {
                setRequesterNameError('')
            }
            if (requesterBranch === '') {
                setRequesterBranchError('Please enter valuation purpose')
            } else {
                setRequesterBranchError('')
            }
            if (requesterPhone === '') {
                setRequesterPhoneError('Please enter valuation purpose')
            } else {
                setRequesterPhoneError('')
            }
            if (name === "") {
                setNameError("please enter title");
            } else {
                setNameError("");
            }
            if (Cnfemail === "") {
                setEmailError("please enter email");
            } else {
                setEmailError("");
            }
            if (mobileCnf.length !== 10) {
                setMobileError("please enter mobile");
            } else {
                setMobileError("");
            }
            if (name === "") {
                setNameError("Please enter client name")
            } else {
                setNameError("")
            }
            if (address === "") {
                setAddressError("Please enter address")
            } else {
                setAddressError("")
            }
            if (state === "") {
                setStateError("Please enter state name")
            } else {
                setStateError("")
            }
            if (city === "") {
                setCityError("Please enter city name ")
            } else {
                setCityError("")
            }
            if (productBrand === "") {
                setProductBrandError("Please enter product brand name")
            } else {
                setProductBrandError("")
            }
            if (model === "") {
                setModelError("Please enter product model")
            }
            else {
                setModelError("")
            }
            if (variant === "") {
                setVariantError("Please select variant of the product")
            } else {
                setVariantError("")
            }
            if (regdNo === "") {
                setRegdNoError("Please enter reg no. of the product")
            } else {
                setRegdNoError("")
            }
        }
    }, [btnPress, requesterName, requesterBranch, requesterPhone, name, Cnfemail, mobileCnf, name, address, state, city, productBrand, model, variant, regdNo]);


    const handleRequesterPhn = async (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setRequesterPhone(value)
    }

    useEffect(() => {
        const getExecutive = employeeList.filter((individualExecutive) =>
            individualExecutive.role === "executive"
        )
        if (getExecutive) {
            setExecutiveList(getExecutive)
        }
        const getAssociate = employeeList.filter((individualExecutive) =>
            individualExecutive.role === "businessAssociate"
        )
        if (getAssociate) {
            setAssociateList(getAssociate)
        }
    }, [employeeList])

    const setNumberChange = async (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setMobileError("");
        setMobileExistError('')
        setMobileCnf(value)
        if (value.length === 10) {
            const verifyNumber = await dispatch(validateMobile(value));
            if (verifyNumber.payload.success) {
                setMobileError("Number already exist");
                setMobileExistError('Number already exist')
            } else {
                setMobileError("");
                setMobileCnf(value);
                setMobileExistError('')
            }
        } else {
            setMobileError("Please Enter Valid Number");
        }
    };

    const emailChange = async (e) => {
        const value = e;
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        setEmail(value);
        setEmailError("");
        setEmailExistError('')
        if (value.length > 0) {
            if (value.match(mailformat)) {
                const verifyEmail = await dispatch(validateEmail(value));
                if (verifyEmail.payload.success) {
                    setEmailError("Number already exist");
                    setEmailExistError("Number already exist");
                } else {
                    setEmailError("");
                    setEmailExistError("");
                    setCnfEmail(value);
                }
            } else {
                setEmailError("please enter correct Email format");
            }
        } else {
            setEmailError("please enter correct Email format");
        }
    };

    const cancelClick = async (e) => {
        e.preventDefault();
        setEmail("");
        setCnfEmail("");
        setMobile("");
        setMobileCnf("");
        setName("");
        setAgent("");
        setExecutive('')
        setClient('')
        setCustomer('')
    };

    const handlesubmit = async (e) => {
        e.preventDefault();
        if (requesterName === '') {
            setRequesterNameError('Please enter valuation purpose')
        } else {
            setRequesterNameError('')
        }
        if (requesterBranch === '') {
            setRequesterBranchError('Please enter valuation purpose')
        } else {
            setRequesterBranchError('')
        }
        if (requesterPhone === '') {
            setRequesterPhoneError('Please enter valuation purpose')
        } else {
            setRequesterPhoneError('')
        }
        if (name === "") {
            setNameError("please enter title");
        } else {
            setNameError("");
        }
        if (Cnfemail === "") {
            setEmailError("please enter email");
        } else {
            setEmailError("");
        }
        if (mobileCnf.length !== 10) {
            setMobileError("please enter mobile");
        } else {
            setMobileError("");
        }
        if (name === "") {
            setNameError("Please enter client name")
        } else {
            setNameError("")
        }
        if (address === "") {
            setAddressError("Please enter address")
        } else {
            setAddressError("")
        }
        if (state === "") {
            setStateError("Please enter state name")
        } else {
            setStateError("")
        }
        if (city === "") {
            setCityError("Please enter city name ")
        } else {
            setCityError("")
        }
        if (productBrand === "") {
            setProductBrandError("Please enter product brand name")
        } else {
            setProductBrandError("")
        }
        if (model === "") {
            setModelError("Please enter product model")
        }
        else {
            setModelError("")
        }
        if (variant === "") {
            setVariantError("Please select variant of the product")
        } else {
            setVariantError("")
        }
        if (regdNo === "") {
            setRegdNoError("Please enter reg no. of the product")
        } else {
            setRegdNoError("")
        }
        if (requesterName !== '' && requesterPhone !== '' && requesterBranch !== '' && name !== "" && Cnfemail !== "" && mobileCnf !== "" && name !== "" && address !== "" && state !== "" && city !== "" && productBrand !== "" && model !== "" && variant !== "" && regdNo !== "") {
            const formData = {
                _id: params._id,
                executive: executive,
                businessAssociate:associate,
                requesterName: requesterName,
                requesterBranch: requesterBranch,
                requesterPhone: requesterPhone,
                name: name,
                mobile: mobileCnf,
                email: Cnfemail,
                address: address,
                state: state,
                city: city,
                regdNo: regdNo,
                brand: productBrand,
                model: model,
                variant: variant,
                status: associate === "" ? 2 : 6,
                statusText: associate === "" ? "Case Assigned" : `Case Assigned To ${associate.name}`
            }
            const newcase = await dispatch(updateIndividualCase(formData))
            if (newcase.payload.success) {
                alert("Case updated successfully");
                setEmail("");
                setCnfEmail("");
                setMobile("");
                setMobileCnf("");
                setName("");
                setRole("")
                setAddress("")
                setState("")
                setCity("")
                setProductBrand("")
                setModel("")
                setVariant("")
                setRegdNo("")
                setAgent("")
                setClient('')
                setExecutive('')
                setCustomer('')
                setRequesterName('')
                setRequesterPhone('')
                setRequesterBranch('')
                setbtnPress(false)
            }
        } else {
            setbtnPress(true);
        }
    };

    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-6 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">
                            Update Case
                        </h4>
                    </div>
                    <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                        <div className="flex items-center justify-between py-4 px-5">
                            <h4 className="text-lg font-medium text-default-950 capitalize">
                                Update Case
                            </h4>
                        </div>
                        <div className="p-5 border-t border-dashed border-default-200">
                            <div className="grid md:grid-cols-2 gap-4">
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Valuation Requested By *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            requesterNameError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fName"
                                        placeholder="Enter Requester Name"
                                        value={requesterName}
                                        onChange={(e) => setRequesterName(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Requester Branch Name *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            requesterBranchError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fName"
                                        placeholder="Enter Requester Branch"
                                        value={requesterBranch}
                                        onChange={(e) => setRequesterBranch(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Requester Phone *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            requesterPhoneError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="lName"
                                        placeholder="Enter Requester Phone"
                                        minLength={10}
                                        maxLength={10}
                                        value={requesterPhone}
                                        onChange={(e) => handleRequesterPhn(e)}
                                    />
                                    <div>{mobileExistError}</div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Name *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            nameError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fName"
                                        placeholder="Enter Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value.trim())}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Phone *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            mobileError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="lName"
                                        placeholder="Enter Phone"
                                        minLength={10}
                                        maxLength={10}
                                        value={mobileCnf}
                                        onChange={(e) => setNumberChange(e)}
                                    />
                                    <div>{mobileExistError}</div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Email *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            emailError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="rollNo"
                                        placeholder="Enter Email"
                                        value={email}
                                        onChange={(e) => emailChange(e.target.value)}
                                    />
                                    <div>{emailExistError}</div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="Address"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Address *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            addressError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="Address"
                                        placeholder="Enter Address"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="state"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        State *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            stateError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="state"
                                        placeholder="Enter state name"
                                        value={state}
                                        onChange={(e) => setState(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="city"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        city *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            cityError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="city"
                                        placeholder="Enter city name"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="regdNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        RegdNo *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            regdNoError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="regdNo"
                                        placeholder="Enter Regd No"
                                        value={regdNo}
                                        onChange={(e) => setRegdNo(e.target.value)}
                                    />
                                </div>
                                <div className=" space-y-2">
                                    <div className="w-full">
                                        <div className="mb-2">
                                            <label
                                                htmlFor="choices-vehicle-brand"
                                                className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                                            >
                                                Choose Vehicle brand *
                                            </label>
                                        </div>
                                        <select
                                            className={
                                                productBrandError !== ""
                                                    ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                    : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                            }
                                            data-trigger
                                            id="choices-vehicle-brand"
                                            placeholder="This is a search placeholder"
                                            value={totalCars._id}
                                            name={totalCars.Make}
                                            onChange={(e) => selectProductBrandChange(e)}
                                        >
                                            <option value="0" hidden selected>
                                                Choose vehicle brand
                                            </option>
                                            {uniqueNames.map((Make, index) => (
                                                <option
                                                    selected={Make === productBrand}
                                                    key={index}
                                                    value={Make}
                                                    name={Make}
                                                    required
                                                >
                                                    {Make}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className=" space-y-2">
                                    <div className="w-full">
                                        <div className="mb-2">
                                            <label
                                                htmlFor="choices-vehicle-model"
                                                className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                                            >
                                                Choose vehicle model *
                                            </label>
                                        </div>
                                        <select
                                            className={
                                                ModelError !== ""
                                                    ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                    : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                            }
                                            data-trigger
                                            id="choices-vehicle-model"
                                            placeholder="This is a search placeholder"
                                            value={totalCars._id}
                                            name={totalCars.Model}
                                            onChange={(e) => selectProductModelChange(e)}
                                        >
                                            <option value="0" hidden selected>
                                                Choose vehicle model
                                            </option>
                                            {totalCars
                                                .filter(car => car.Make === productBrand)
                                                .filter((car, index, self) => self.findIndex(c => c.Model === car.Model) === index) // Filter out duplicates
                                                .map((car, index) => (
                                                    <option
                                                        selected={car.Model === model}
                                                        key={index}
                                                        value={car.Model}
                                                        name={car.Model}
                                                        required
                                                    >
                                                        {car.Model}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                </div>
                                <div className=" space-y-2">
                                    <div className="w-full">
                                        <div className="mb-2">
                                            <label
                                                htmlFor="choices-vehicle-variant"
                                                className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                                            >
                                                Choose vehicle variant *
                                            </label>
                                        </div>
                                        <select
                                            className={
                                                variantError !== ""
                                                    ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                    : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                            }
                                            data-trigger
                                            id="choices-vehicle-variant"
                                            placeholder="This is a search placeholder"
                                            value={totalCars.id}
                                            name={totalCars.name}
                                            onChange={(e) => selectProductVariantChange(e)}
                                        >
                                            <option value="0" hidden selected>
                                                Choose vehicle variant
                                            </option>
                                            {totalCars.map((test, index) => {
                                                if (test.Make === productBrand && test.Model === model) {
                                                    return (
                                                        <option
                                                            selected={test.Variant === variant}
                                                            key={index}
                                                            value={test.id}
                                                            name={test.Variant}
                                                            required
                                                        >
                                                            {test.Variant}
                                                        </option>
                                                    )
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className=" space-y-1">
                                        <div className="w-full">
                                            <div className="mb-2">
                                                <label
                                                    htmlFor="choices-single-default"
                                                    className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                                                >
                                                    Select Associate
                                                </label>
                                            </div>
                                            <select
                                                className={`border-default-200 w-full `}
                                                data-trigger
                                                id="choices-associate"
                                                placeholder="This is a search placeholder"
                                                value={associateList._id}
                                                name={associateList.name}
                                                onChange={(e) => selectAssociateChange(e)}
                                            >
                                                <option value="0" hidden selected>
                                                    Choose Associate
                                                </option>
                                                {associateList.map((test, index) => (
                                                    <option
                                                        selected={test.name === associate.name}
                                                        key={index}
                                                        value={test._id}
                                                        name={test.name}
                                                        required
                                                    >
                                                        {test.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                <div className=" space-y-1">
                                    <div className="w-full">
                                        <div className="mb-2">
                                            <label
                                                htmlFor="choices-single-default"
                                                className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                                            >
                                                Select Executive
                                            </label>
                                        </div>
                                        <select
                                            className={`border-default-200 w-full `}
                                            data-trigger
                                            id="choices-single-default"
                                            placeholder="This is a search placeholder"
                                            value={executiveList._id}
                                            name={executiveList.name}
                                            onChange={(e) => selectExecutiveChange(e)}
                                        >
                                            <option value="0" hidden selected>
                                                Choose Executive
                                            </option>
                                            {executiveList.map((test, index) => (
                                                <option
                                                    selected={test.name === executive.name}
                                                    key={index}
                                                    value={test._id}
                                                    name={test.name}
                                                    required
                                                >
                                                    {test.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {/* <div className=" space-y-1">
                                    <div className="w-full">
                                        <div className="mb-2">
                                            <label
                                                htmlFor="choices-single-default"
                                                className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                                            >
                                                Select Agent
                                            </label>
                                        </div>
                                        <select
                                            className={
                                                agentError !== ""
                                                    ? "border-default-200 w-full error_class"
                                                    : " border-default-200 w-full"
                                            }
                                            data-trigger
                                            id="choices-single-default"
                                            placeholder="This is a search placeholder"
                                            value={agentList._id}
                                            name={agentList.name}
                                            onChange={(e) => selectAgentChange(e)}
                                        >
                                            <option value="0" hidden selected>
                                                Choose Agent
                                            </option>
                                            {agentList.map((test, index) => (
                                                <option
                                                    selected={test.name === agent.name}
                                                    key={index}
                                                    value={test._id}
                                                    name={test.name}
                                                    required
                                                >
                                                    {test.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div> */}
                                {/* <div className=" space-y-1">
                                    <div className="w-full">
                                        <div className="mb-2">
                                            <label
                                                htmlFor="choices-single-default"
                                                className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                                            >
                                                Select Client
                                            </label>
                                        </div>
                                        <select
                                            className={`border-default-200 w-full `}
                                            data-trigger
                                            id="choices-single-default"
                                            placeholder="This is a search placeholder"
                                            value={clientList._id}
                                            name={clientList.name}
                                            onChange={(e) => selectClientChange(e)}
                                        >
                                            <option value="0" hidden selected>
                                                Choose Client
                                            </option>
                                            {clientList.map((clnt, index) => (
                                                <option
                                                    selected={clnt.name === client.name}
                                                    key={index}
                                                    value={clnt._id}
                                                    name={clnt.name}
                                                    required
                                                >
                                                    {clnt.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div> */}
                                {/* <div className=" space-y-1">
                                    <div className="w-full">
                                        <div className="mb-2">
                                            <label
                                                htmlFor="choices-single-default"
                                                className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                                            >
                                                Select Customer
                                            </label>
                                        </div>
                                        <select
                                            className={`border-default-200 w-full `}
                                            data-trigger
                                            id="choices-single-default"
                                            placeholder="This is a search placeholder"
                                            value={customerList._id}
                                            name={customerList.name}
                                            onChange={(e) => selectCustomerChange(e)}
                                        >
                                            <option value="0" hidden selected>
                                                Choose Customer
                                            </option>
                                            {customerList.map((cstm, index) => (
                                                <option
                                                    selected={cstm.name === customer.name}
                                                    key={index}
                                                    value={cstm._id}
                                                    name={cstm.name}
                                                    required
                                                >
                                                    {cstm.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-2 justify-end" style={{ marginBottom: '80px' }}>
                        <button
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                            style={{ background: "#8481ff" }}
                            onClick={(e) => handlesubmit(e)}
                        >
                            <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateCase;
