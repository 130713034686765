import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { InputAdornment, TextField } from "@mui/material";

const ComEditReport = () => {
  const params = useParams();
  const navigation = useNavigate();
  const [assetType, setAssetType] = useState("");
  const [airConditioner, setAirConditioner] = useState("");
  const [cdCharge, setCdCharge] = useState("");
  const [seatCover, setSeatCover] = useState("");
  const [meterReading, setMeterReading] = useState('');
  const [fogLamps, setFogLamps] = useState("");
  const [transmission, setTransmission] = useState("");
  const [odometer, setOdometer] = useState("");
  const [accidental, setAccidental] = useState("");
  const [otherRepair, setotherRepair] = useState("");
  const [fitnessExpiry, setFitnessExpiry] = useState("");
  const [roadtaxValidity, setroadtaxValidity] = useState("");
  const [idvValue, setIdvValue] = useState("");
  const [insuranceExpiry, setInsuranceExpiry] = useState("");
  const [engineCondition, setEngineCondition] = useState("");
  const [engineConditionError, setEngineConditionError] = useState("");
  const [wheelType, setWheelType] = useState("");
  const [totalTyre, setTotalTyre] = useState("");
  const [availableTyre, setAvailableTyre] = useState("");
  const [missingTyre, setMissingTyre] = useState("");
  const [spareTyre, setSpareTyre] = useState("");
  const [interiorCondition, setInteriorCondition] = useState("");
  const [interiorConditionError, setInteriorConditionError] = useState("");
  const [exteriorCondition, setExteriorCondition] = useState("");
  const [exteriorConditionError, setExteriorConditionError] = useState("");
  const [headLamp, setHeadLamp] = useState("Satisfactory");
  const [tailLamp, setTailLamp] = useState("Satisfactory");
  const [frontIndicators, setFrontIndicators] = useState("Satisfactory");
  const [rearIndicators, setRearIndicators] = useState("Satisfactory");
  const [maintenanceLevel, setMaintenanceLevel] = useState("Satisfactory");
  const [frontBrakeCondition, setFrontBreakCondition] = useState("");
  const [frontBrakeConditionError, setFrontBreakConditionError] = useState("");
  const [rearBrakeCondition, setRearBreakCondition] = useState("");
  const [rearBrakeConditionError, setRearBreakConditionError] = useState("");
  const [comFrontCrashGuard, setComFrontCrashGuard] = useState("");
  const [comLoadCarrier, setComLoadCarrier] = useState("");
  const [comMusicSystem, setComMusicSystem] = useState("");
  const [comRearUnderRun, setComRearUnderRun] = useState("");
  const [comSideUnderRun, setComSideUnderRun] = useState("");
  const [comToolkit, setComToolkit] = useState("");
  const [comTvVideo, setComTvVideo] = useState("");
  const [comBodyCondition, setComBodyCondition] = useState("Satisfactory");
  const [comHandBrake, setComHandBrake] = useState("Satisfactory");
  const [comDoorCondition, setComDoorCondition] = useState("Satisfactory");
  const [comSideGlassesCondition, setComSideGlassesCondition] = useState("Satisfactory");
  const [comWindscreenCondition, setComWindscreenCondition] = useState("Satisfactory");
  const [comBatteryCondition, setComBatteryCondition] = useState("Satisfactory");
  const [comClusterUnitCondition, setComClusterUnitCondition] = useState("Satisfactory");
  const [comFogLampStatus, setComFogLampStatus] = useState("");
  const [comBatteryNo, setComBatteryNo] = useState("");
  const [comWiperStatus, setComWiperStatus] = useState("");
  const [comEngineStatus, setComEngineStatus] = useState("");
  const [comEngineShieldCondition, setComEngineShieldCondition] = useState("Satisfactory");
  const [comFluidLeakStatus, setComFluidLeakStatus] = useState("");
  const [comSuspensionCondition, setComSuspensionCondition] = useState("Satisfactory");
  const [comSuspensionConditionError, setComSuspensionConditionError] = useState("");
  const [comTransmissionCondition, setComTransmissionCondition] = useState("Satisfactory");
  const [comTransmissionConditionError, setComTransmissionConditionError] = useState("");
  const [comAxleOneLeftIn, setComAxleOneLeftIn] = useState("");
  const [comAxleOneLeftOut, setComAxleOneLeftOut] = useState("");
  const [comAxleOneRightIn, setComAxleOneRightIn] = useState("");
  const [comAxleOneRightOut, setComAxleOneRightOut] = useState("");
  const [comAxleThreeLeftIn, setComAxleThreeLeftIn] = useState("");
  const [comAxleThreeLeftOut, setComAxleThreeLeftOut] = useState("");
  const [comAxleThreeRightIn, setComAxleThreeRightIn] = useState("");
  const [comAxleThreeRightOut, setComAxleThreeRightOut] = useState("");
  const [comAxleTwoLeftIn, setComAxleTwoLeftIn] = useState("");
  const [comAxleTwoLeftOut, setComAxleTwoLeftOut] = useState("");
  const [comAxleTwoRightIn, setComAxleTwoRightIn] = useState("");
  const [comAxleTwoRightOut, setComAxleTwoRightOut] = useState("");
  const [comFrontOneLeft, setComFrontOneLeft] = useState("");
  const [comFrontOneRight, setComFrontOneRight] = useState("");
  const [comFrontTwoLeft, setComFrontTwoLeft] = useState("");
  const [comFrontTwoRight, setComFrontTwoRight] = useState("");
  const [comFrontTruckTyreCondition, setComFrontTruckTyreCondition] =
    useState("Satisfactory");
  const [comFrontTruckTyreConditionError, setComFrontTruckTyreConditionError] =
    useState("");
  const [comFrontTruckTyreType, setComFrontTruckTyreType] = useState("");
  const [comAxleCondition, setComAxleCondition] = useState("Satisfactory");

  const [yourRemark, setYourRemark] = useState("");
  const [exShowRoomPrice, setExShowRoomPrice] = useState("");
  const [exShowRoomPriceError, setExShowRoomPriceError] = useState("");
  const [estimatedPrice, setEstimatedPrice] = useState("");
  const [estimatedPriceError, setEstimatedPriceError] = useState("");
  const [urlPath, setUrlPath] = useState("");
  const [depreciatedPrice, setDepreciatedPrice] = useState("");
  const [vehicleEdetails, setVehicleEdetails] = useState('')
  const [imagePath, setImagePath] = useState("");
  const [btnPress, setBtnPress] = useState(false)
  const selectCaseType = [
    {
      id: 1,
      name: "Poor",
    },
    {
      id: 2,
      name: "Average",
    },
    {
      id: 3,
      name: "Satisfactory",
    },
    {
      id: 4,
      name: "Good",
    },
    {
      id: 5,
      name: "Excellent",
    },
  ];
  const selectCaseChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setYourRemark(text);
  };

  const reqCondition = [
    { name: "Poor", id: 1 },
    { name: "Average", id: 2 },
    { name: "Satisfactory", id: 3 },
    { name: "Good", id: 4 },
    { name: "Excellent", id: 5 },
  ];
  const condition = [
    { name: "Poor", id: 1 },
    { name: "Average", id: 2 },
    { name: "Satisfactory", id: 3 },
    { name: "Good", id: 4 },
    { name: "Excellent", id: 5 },
    { name: "NA", id: 6 },
  ];
  const list = [
    { name: "10", id: 1 },
    { name: "20", id: 2 },
    { name: "30", id: 3 },
    { name: "40", id: 4 },
    { name: "50", id: 5 },
    { name: "60", id: 6 },
    { name: "70", id: 7 },
    { name: "80", id: 8 },
    { name: "90", id: 9 },
    { name: "100", id: 10 },
  ];
  const qualityList = [
    { name: "Original", id: 1 },
    { name: "Replaced", id: 2 },
  ];
  const options = [
    { name: "Yes", id: 1 },
    { name: "No", id: 2 },
  ];
  const wheelTypeList = [
    { name: "Alloy", id: 1 },
    { name: "Steel", value: 2 },
    { name: "Rim", value: 3 },
  ];
  const typeList = [
    { id: 1, name: "Digital" },
    { id: 2, name: "Normal" },
  ];
  const Features = [
    { name: "Manual", id: 2 },
    { name: "Automatic", id: 3 },
  ];
  const attachment = [
    { name: "One Trolly", id: 1 },
    { name: "Two Trolly", id: 2 },
  ];
  const selectAirConditionerChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setAirConditioner(text);
  };
  const selectCdChargeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCdCharge(text);
  };
  const selectSeatCoverChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setSeatCover(text);
  };
  const selectFogLampsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFogLamps(text);
  };
  const selectTransmissionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTransmission(text);
  };
  const selectOdometerChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setOdometer(text);
  };
  const selectAccidentalChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setAccidental(text);
  };
  const selectotherRepairlChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setotherRepair(text);
  };
  const selectEngineConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setEngineCondition(text);
  };
  const selectWheelTypeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setWheelType(text);
  };
  const selectInteriorConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setInteriorCondition(text);
  };
  const selectExteriorConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setExteriorCondition(text);
  };
  const selectHeadLampChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setHeadLamp(text);
  };
  const selectTailLampChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTailLamp(text);
  };
  const selectFrontIndicatorsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFrontIndicators(text);
  };
  const selectRearIndicatorsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setRearIndicators(text);
  };
  const selectMaintenanceLevelChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setMaintenanceLevel(text);
  };
  const selectFrontBreakConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFrontBreakCondition(text);
  };
  const selectRearBreakConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setRearBreakCondition(text);
  };
  const selectsetFrontCrashGuardChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComFrontCrashGuard(text);
  };
  const selectComLoadCarrierChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComLoadCarrier(text);
  };
  const selectComMusicSystemChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComMusicSystem(text);
  };
  const selectComRearUnderRunChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComRearUnderRun(text);
  };
  const selectComSideUnderRunChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComSideUnderRun(text);
  };
  const selectComToolkitChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComToolkit(text);
  };
  const selectComTvVideoChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComTvVideo(text);
  };
  const selectComBodyConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComBodyCondition(text);
  };
  const selectComHandBrakeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComHandBrake(text);
  };
  const selectComDoorConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComDoorCondition(text);
  };
  const selectComSideGlassesConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComSideGlassesCondition(text);
  };
  const selectComWindscreenConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComWindscreenCondition(text);
  };
  const selectComBatteryConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComBatteryCondition(text);
  };
  const selectComClusterUnitConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComClusterUnitCondition(text);
  };
  const selectComFogLampStatusChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComFogLampStatus(text);
  };
  const selectComWiperStatusChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComWiperStatus(text);
  };
  const selectComEngineStatusChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComEngineStatus(text);
  };
  const selectComEngineShieldConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComEngineShieldCondition(text);
  };
  const selectComFluidLeakStatusChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComFluidLeakStatus(text);
  };
  const selectComSuspensionConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComSuspensionCondition(text);
  };
  const selectComTransmissionConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComTransmissionCondition(text);
  };
  const selectComAxleOneLeftInChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComAxleOneLeftIn(text);
  };
  const selectComAxleOneLeftOutChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComAxleOneLeftOut(text);
  };
  const selectComAxleOneRightInChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComAxleOneRightIn(text);
  };
  const selectComAxleOneRightOutChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComAxleOneRightOut(text);
  };
  const selectComAxleThreeLeftInChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComAxleThreeLeftIn(text);
  };
  const selectComAxleThreeLeftOutChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComAxleThreeLeftOut(text);
  };
  const selectComAxleThreeRightInChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComAxleThreeRightIn(text);
  };
  const selectComAxleThreeRightOutChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComAxleThreeRightOut(text);
  };
  const selectComAxleTwoLeftInChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComAxleTwoLeftIn(text);
  };
  const selectComAxleTwoLeftOutChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComAxleTwoLeftOut(text);
  };
  const selectComAxleTwoRightInChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComAxleTwoRightIn(text);
  };
  const selectComAxleTwoRightOutChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComAxleTwoRightOut(text);
  };
  const selectComFrontOneLeftChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComFrontOneLeft(text);
  };
  const selectComFrontOneRightChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComFrontOneRight(text);
  };
  const selectComFrontTwoLeftChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComFrontTwoLeft(text);
  };
  const selectComFrontTwoRightChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComFrontTwoRight(text);
  };
  const selectComFrontTruckTyreConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComFrontTruckTyreCondition(text);
  };
  const selectComFrontTruckTyreTypeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComFrontTruckTyreType(text);
  };
  const selectComAxleConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setComAxleCondition(text);
  };
  const selectYourRemarkChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setYourRemark(text);
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/cases/individualcase/${params.id}`;
      const resp = await axios.get(url);
      if (resp.data.success) {
        const data = resp.data.case;
        console.log(resp.data.case, "resp.data.case")
        setVehicleEdetails(data.vehicleEdetails);
        setAssetType(data.assetType);
        setAirConditioner(data.accessories.ac);
        setCdCharge(data.accessories.cdCharger);
        setMeterReading(data?.meterReading);
        setSeatCover(data.accessories.seatCover);
        setFogLamps(data.accessories.fogLamps);
        setTransmission(data.additionalFeatures.transmission);
        setOdometer(data.additionalFeatures.odometer);
        setIdvValue(data.otherDetails.IDVValue);
        setAccidental(data.otherDetails.accidental);
        setotherRepair(data.otherDetails.otherRepair);
        setFitnessExpiry(data.otherDetails.fitnessExpiry);
        setroadtaxValidity(data.otherDetails.roadTaxValidity);
        setInsuranceExpiry(data.otherDetails.insuranceExpiry);
        setEngineCondition(data.otherDetails.engineCondition);
        setWheelType(data.otherDetails.wheelsType);
        setTotalTyre(data.otherDetails.totalTyre);
        setMissingTyre(data.otherDetails.missingTyre);
        setSpareTyre(data.otherDetails.spareTyre);
        setAvailableTyre(data.otherDetails.availableTyre);
        setFrontBreakCondition(data.otherDetails.frontBrakeCondition);
        setRearBreakCondition(data.otherDetails.rearBrakeCondition);
        setInteriorCondition(data.otherDetails.interiorCondition);
        setExteriorCondition(data.otherDetails.exteriorCondition);
        setHeadLamp(data.otherDetails.headLamp);
        setTailLamp(data.otherDetails.tailLamp);
        setFrontIndicators(data.otherDetails.frontIndicators);
        setRearIndicators(data.otherDetails.rearIndicators);
        setMaintenanceLevel(data.otherDetails.levelOfMaintenance);

        setComFrontCrashGuard(data.commercial.accessories.frontCrashGuard);
        setComLoadCarrier(data.commercial.accessories.loadCarrier);
        setComMusicSystem(data.commercial.accessories.musicSystem);
        setComRearUnderRun(data.commercial.accessories.rearUnderRunProtection);
        setComSideUnderRun(data.commercial.accessories.sideUnderRunProtection);
        setComToolkit(data.commercial.accessories.toolKit);
        setComTvVideo(data.commercial.accessories.tvNvideoPlayer);
        setComBodyCondition(data.commercial.body.bodyCondition);
        setComHandBrake(data.commercial.brakes.handBrake);
        setComDoorCondition(data.commercial.cabinNinterior.doors);
        setComSideGlassesCondition(data.commercial.cabinNinterior.sideGlasses);
        setComWindscreenCondition(data.commercial.cabinNinterior.windScreen);
        setComBatteryCondition(data.commercial.electricals.batteryCondition);
        setComClusterUnitCondition(data.commercial.electricals.clusterUnit);
        setComFogLampStatus(data.commercial.electricals.fogLamps);
        setComBatteryNo(data.commercial.electricals.noOfBatteries);
        setComWiperStatus(data.commercial.electricals.wiper);
        setComEngineStatus(data.commercial.engine.engine);
        setComEngineShieldCondition(data.commercial.engine.engineShield);
        setComFluidLeakStatus(data.commercial.engine.fluidLeak);
        setComSuspensionCondition(
          data.commercial.suspension.suspensionCondition
        );
        setComTransmissionCondition(
          data.commercial.transmission.transmissionCondition
        );
        setComAxleOneLeftIn(data.commercial.tyresNConditions.axleOneLeftIn);
        setComAxleOneLeftOut(data.commercial.tyresNConditions.axleOneLeftOut);
        setComAxleOneRightIn(data.commercial.tyresNConditions.axleOneRightIn);
        setComAxleOneRightOut(data.commercial.tyresNConditions.axleOneRightOut);
        setComAxleThreeLeftIn(data.commercial.tyresNConditions.axleThreeLeftIn);
        setComAxleThreeLeftOut(
          data.commercial.tyresNConditions.axleThreeLeftOut
        );
        setComAxleThreeRightIn(
          data.commercial.tyresNConditions.axleThreeRightIn
        );
        setComAxleThreeRightOut(
          data.commercial.tyresNConditions.axleThreeRightOut
        );
        setComAxleTwoLeftIn(data.commercial.tyresNConditions.axleTwoLeftIn);
        setComAxleTwoLeftOut(data.commercial.tyresNConditions.axleTwoLeftOut);
        setComAxleTwoRightIn(data.commercial.tyresNConditions.axleTwoRightIn);
        setComAxleTwoRightOut(data.commercial.tyresNConditions.axleTwoRightOut);
        setComFrontOneLeft(data.commercial.tyresNConditions.frontOneLeft);
        setComFrontOneRight(data.commercial.tyresNConditions.frontOneRight);
        setComFrontTwoLeft(data.commercial.tyresNConditions.frontTwoLeft);
        setComFrontTwoRight(data.commercial.tyresNConditions.frontTwoRight);
        setComFrontTruckTyreCondition(
          data.commercial.tyresNConditions.truckTyreCondition
        );
        setComFrontTruckTyreType(data.commercial.tyresNConditions.tyreType);
        setComAxleCondition(data.commercial.tyresNConditions.axlesCondition);

        setYourRemark(data.yourRemark);
        setExShowRoomPrice(data.marketPrice);
        setEstimatedPrice(data.valuation);
        let path = "";
        if (data.assetType === "Two Wheeler") {
          path = `https://instavaluer.com/twowheelerreportrelease/${params.id}`;
        } else if (data.assetType === "Four Wheeler") {
          path = `https://instavaluer.com/releasereport/${params.id}`;
        } else if (data.assetType === "Commercial") {
          path = `https://instavaluer.com/commercialreportrelease/${params.id}`;
        } else if (data.assetType === "Construction Equipment") {
          path = `https://instavaluer.com/constructionreportrelease/${params.id}`;
        } else if (data.assetType === 'Three Wheeler') {
          path = `https://instavaluer.com/threewheelerreportrelease/${params._id}`
        } else if (data.assetType === 'Farm Equipment') {
          path = `https://instavaluer.com/firmequipmentreportrelease/${params._id}`
        }
        setUrlPath(path);
        const imagePath = `https://instavaluer.com/imagereport/${params.id}`
        setImagePath(imagePath)
      }
    };
    fetchData();
  }, [params.id]);


  useEffect(() => {
    if (btnPress === true) {
      if (engineCondition === '') {
        setEngineConditionError('Please enter engine condition')
      } else {
        setEngineConditionError('')
      }
      if (interiorCondition === '') {
        setInteriorConditionError('Please enter interior condition')
      } else {
        setInteriorConditionError('')
      }
      if (exteriorCondition === '') {
        setExteriorConditionError('Please enter interior condition')
      } else {
        setExteriorConditionError('')
      }
      if (frontBrakeCondition === '') {
        setFrontBreakConditionError('Please enter interior condition')
      } else {
        setFrontBreakConditionError('')
      }
      if (rearBrakeCondition === '') {
        setRearBreakConditionError('Please enter interior condition')
      } else {
        setRearBreakConditionError('')
      }
      if (comSuspensionCondition === '') {
        setComSuspensionConditionError('Please enter interior condition')
      } else {
        setComSuspensionConditionError('')
      }
      if (comTransmissionCondition === '') {
        setComTransmissionConditionError('Please enter interior condition')
      } else {
        setComTransmissionConditionError('')
      }
      if (comFrontTruckTyreCondition === '') {
        setComFrontTruckTyreConditionError('Please enter interior condition')
      } else {
        setComFrontTruckTyreConditionError('')
      }
      if (exShowRoomPrice === '') {
        setExShowRoomPriceError('Please enter interior condition')
      } else {
        setExShowRoomPriceError('')
      }
      if (estimatedPrice === '') {
        setEstimatedPriceError('Please enter interior condition')
      } else {
        setEstimatedPriceError('')
      }
    }
  }, [btnPress, engineCondition, interiorCondition, exteriorCondition, frontBrakeCondition,
    rearBrakeCondition, comSuspensionCondition,
    comTransmissionCondition, comFrontTruckTyreCondition, exShowRoomPrice, estimatedPrice])

  const handleSubmit = async () => {
    if (engineCondition === '') {
      setEngineConditionError('Please enter engine condition')
    } else {
      setEngineConditionError('')
    }
    if (interiorCondition === '') {
      setInteriorConditionError('Please enter interior condition')
    } else {
      setInteriorConditionError('')
    }
    if (exteriorCondition === '') {
      setExteriorConditionError('Please enter interior condition')
    } else {
      setExteriorConditionError('')
    }
    if (frontBrakeCondition === '') {
      setFrontBreakConditionError('Please enter interior condition')
    } else {
      setFrontBreakConditionError('')
    }
    if (rearBrakeCondition === '') {
      setRearBreakConditionError('Please enter interior condition')
    } else {
      setRearBreakConditionError('')
    }
    if (comSuspensionCondition === '') {
      setComSuspensionConditionError('Please enter interior condition')
    } else {
      setComSuspensionConditionError('')
    }
    if (comTransmissionCondition === '') {
      setComTransmissionConditionError('Please enter interior condition')
    } else {
      setComTransmissionConditionError('')
    }
    if (comFrontTruckTyreCondition === '') {
      setComFrontTruckTyreConditionError('Please enter interior condition')
    } else {
      setComFrontTruckTyreConditionError('')
    }
    if (exShowRoomPrice === '') {
      setExShowRoomPriceError('Please enter interior condition')
    } else {
      setExShowRoomPriceError('')
    }
    if (estimatedPrice === '') {
      setEstimatedPriceError('Please enter interior condition')
    } else {
      setEstimatedPriceError('')
    }
    if (engineCondition !== '' && interiorCondition !== '' && exteriorCondition !== '' && frontBrakeCondition !== '' && rearBrakeCondition !== '' && comSuspensionCondition !== '' && comTransmissionCondition !== '' && comFrontTruckTyreCondition !== '' && exShowRoomPrice !== '' && estimatedPrice !== '') {

      const currentDate = new Date();
      const todayDate = currentDate.toISOString().slice(0, 10)

      const formData = {
        meterReading:meterReading,
        accessories: {
          ac: airConditioner,
          cdCharger: cdCharge,
          seatCover: seatCover,
          fogLamps: fogLamps,
        },
        additionalFeatures: {
          transmission: transmission,
          odometer: odometer,
        },
        otherDetails: {
          accidental: accidental,
          otherRepair: otherRepair,
          fitnessExpiry: fitnessExpiry,
          roadTaxValidity: roadtaxValidity,
          IDVValue: idvValue,
          insuranceExpiry: insuranceExpiry,
          engineCondition: engineCondition,
          frontBrakeCondition: frontBrakeCondition,
          rearBrakeCondition: rearBrakeCondition,
          wheelsType: wheelType,
          totalTyre: totalTyre,
          availableTyre: availableTyre,
          missingTyre: missingTyre,
          spareTyre: spareTyre,
          interiorCondition: interiorCondition,
          exteriorCondition: exteriorCondition,
          headLamp: headLamp,
          tailLamp: tailLamp,
          frontIndicators: frontIndicators,
          rearIndicators: rearIndicators,
          levelOfMaintenance: maintenanceLevel,
        },
        commercial: {
          engine: {
            engine: comEngineStatus,
            fluidLeak: comFluidLeakStatus,
            engineShield: comEngineShieldCondition,
          },
          transmission: {
            transmissionCondition: comTransmissionCondition,
          },
          suspension: {
            suspensionCondition: comSuspensionCondition,
          },
          brakes: {
            handBrake: comHandBrake,
          },
          tyresNConditions: {
            tyreType: comFrontTruckTyreType,
            truckTyreCondition: comFrontTruckTyreCondition,
            axlesCondition: comAxleCondition,
            frontOneLeft: comFrontOneLeft,
            frontTwoLeft: comFrontTwoLeft,
            axleOneLeftIn: comAxleOneLeftIn,
            axleOneRightIn: comAxleOneRightIn,
            axleTwoLeftIn: comAxleTwoLeftIn,
            axleTwoRightIn: comAxleTwoRightIn,
            axleThreeLeftIn: comAxleThreeLeftIn,
            axleThreeRightIn: comAxleThreeRightIn,
            frontOneRight: comFrontOneRight,
            frontTwoRight: comFrontTwoRight,
            axleOneLeftOut: comAxleOneRightIn,
            axleOneRightOut: comAxleOneRightOut,
            axleTwoLeftOut: comAxleTwoRightIn,
            axleTwoRightOut: comAxleTwoRightOut,
            axleThreeLeftOut: comAxleThreeLeftOut,
            axleThreeRightOut: comAxleThreeRightOut,
          },
          cabinNinterior: {
            windScreen: comWindscreenCondition,
            sideGlasses: comSideGlassesCondition,
            doors: comDoorCondition,
          },
          electricals: {
            noOfBatteries: comBatteryNo,
            batteryCondition: comBatteryCondition,
            clusterUnit: comClusterUnitCondition,
            fogLamps: comFogLampStatus,
            wiper: comWiperStatus,
          },
          accessories: {
            frontCrashGuard: comFrontCrashGuard,
            loadCarrier: comLoadCarrier,
            musicSystem: comMusicSystem,
            tvNvideoPlayer: comTvVideo,
            rearUnderRunProtection: comRearUnderRun,
            sideUnderRunProtection: comSideUnderRun,
            toolKit: comToolkit,
          },
          body: {
            bodyCondition: comBodyCondition,
          },
        },
        yourRemark: yourRemark,
        valuation: estimatedPrice,
        marketPrice: exShowRoomPrice,
        reportUrl: urlPath,
        imageUrl: imagePath,
        submittedAt: todayDate,
        status: 5,
        statusText: "Case Url Updated",
        currentForm: 4,
      };
      console.log(formData, "formData");
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/cases/updatecase/${params.id}`;
      const resp = await axios.put(url, formData, config);
      if (resp.data.success) {
        navigation(`/commercialreportrelease/${params.id}`);
      }
    } else {
      setBtnPress(true)
    }
  };

  useEffect(() => {
    if (comTransmissionCondition !== '' &&
      engineCondition !== '' &&
      comSuspensionCondition !== '' &&
      interiorCondition !== '' &&
      exteriorCondition !== '' &&
      comFrontTruckTyreCondition !== '' &&
      rearBrakeCondition !== '' &&
      frontBrakeCondition !== '' &&
      exShowRoomPrice !== ''
    ) {
      const roundImageArray = [
        { label: "Poor", percentage: 2 },
        { label: "Average", percentage: 4 },
        { label: "Satisfactory", percentage: 5 },
        { label: "Good", percentage: 7 },
        { label: "Excellent", percentage: 9 },
      ];
      const findPercentageByLabel = (label) => {
        const found = roundImageArray.find((item) => item.label === label)
        return found ? found.percentage : 0
      }

      const findEngineCon = findPercentageByLabel(engineCondition);
      const findTransmissionCon = findPercentageByLabel(comTransmissionCondition);
      const findSuspensionCon = findPercentageByLabel(comSuspensionCondition);
      const findInteriorCon = findPercentageByLabel(interiorCondition);
      const findExteriorCon = findPercentageByLabel(exteriorCondition);
      const findTyreCon = findPercentageByLabel(comFrontTruckTyreCondition);

      const findBrakesCon = Math.floor((frontBrakeCondition + rearBrakeCondition) / 2);

      const getFinalCondition = (value) => {
        if (value <= 2) return 2;
        else if (value <= 4) return 4;
        else if (value <= 6) return 5;
        else if (value <= 8) return 7;
        else return 9;
      };

      const finalBreakCondition = getFinalCondition(findBrakesCon);

      const overalRating =
        Number(findEngineCon) * 3 +
        Number(findTransmissionCon) +
        Number(findSuspensionCon) +
        Number(findInteriorCon) +
        Number(findExteriorCon) +
        Number(finalBreakCondition) +
        Number(findTyreCon);

      console.log(overalRating, 'overalRating');
      const overalavg = Number(overalRating) / Number(10);

      console.log(overalavg, 'overalavg');
      let deprePerYear = {};

      if (overalavg >= 8) {
        deprePerYear = { 1: 7, 2: 14, 3: 18, 4: 20, 5: 25, 6: 28, 7: 32, 8: 34, 9: 36, 10: 38, 11: 48 };
      } else if (overalavg >= 7 && overalavg < 8) {
        deprePerYear = { 1: 10, 2: 16, 3: 22, 4: 25, 5: 28, 6: 30, 7: 35, 8: 38, 9: 38, 10: 40, 11: 48 };
      } else if (overalavg >= 6 && overalavg < 7) {
        deprePerYear = { 1: 15, 2: 20, 3: 25, 4: 30, 5: 35, 6: 40, 7: 42, 8: 42, 9: 44, 10: 46, 11: 48 };
      } else if (overalavg >= 5 && overalavg < 6) {
        deprePerYear = { 1: 25, 2: 35, 3: 45, 4: 47, 5: 49, 6: 52, 7: 53, 8: 55, 9: 55, 10: 55, 11: 55 };
      }

      const mfg = vehicleEdetails.vehicleManufacturingMonthYear.slice(-4);
      const currentYear = new Date().getFullYear();
      const depreYear = currentYear - parseInt(mfg, 10);
      console.log(depreYear, 'depreYear');
      if (depreYear >= 11) {
        const percentageValue = (48 / 100) * exShowRoomPrice;
        const depreciationValue = exShowRoomPrice - percentageValue;
        setEstimatedPrice(Math.floor(depreciationValue));
        setDepreciatedPrice(Math.floor(depreciationValue));
      } else if (deprePerYear.hasOwnProperty(depreYear)) {
        const percentageValue = (deprePerYear[depreYear] / 100) * exShowRoomPrice;
        const depreciationValue = exShowRoomPrice - percentageValue;
        console.log(depreciationValue, 'depreciationValue');
        setEstimatedPrice(Math.floor(depreciationValue));
        setDepreciatedPrice(Math.floor(depreciationValue))
      }
    }

  }, [comTransmissionCondition, engineCondition, comSuspensionCondition,
    interiorCondition, exteriorCondition, comFrontTruckTyreCondition,
    rearBrakeCondition, frontBrakeCondition, exShowRoomPrice
  ])

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div className="flex items-center justify-between py-2 px-5"
              style={{ backgroundColor: '#4D44B5', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
            >
              <h4 className="text-lg font-medium text-default-950 capitalize" style={{ color: '#fff' }}>
                Edit Case Details
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-3 gap-4">
                <div className="space-y-1">
                  <label
                    htmlFor="airConditioner"
                    className="text-sm font-medium text-default-900"
                  >
                    Air Conditioner *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="airConditioner"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectAirConditionerChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Air Conditioner
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === airConditioner}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="cdCharge"
                    className="text-sm font-medium text-default-900"
                  >
                    Cd Charge *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="cdCharge"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectCdChargeChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Cd Charge
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === cdCharge}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="seatCover"
                    className="text-sm font-medium text-default-900"
                  >
                    Seat Cover *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="seatCover"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectSeatCoverChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Seat Cover
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === seatCover}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fMeterReading"
                    className="text-sm font-medium text-default-900"
                  >
                    Meter Reading *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Meter Reading"
                      variant="outlined"
                      className={
                        "textfield"
                      }
                      style={{ marginTop: '5px' }}
                      type="text"
                      id="text"
                      value={meterReading}
                      onChange={(e) => setMeterReading(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fogLamps"
                    className="text-sm font-medium text-default-900"
                  >
                    Fog Lamps *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="fogLamps"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectFogLampsChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Fog Lamps
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === fogLamps}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="transmission"
                    className="text-sm font-medium text-default-900"
                  >
                    Transmission *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="transmission"
                    placeholder="This is a search placeholder"
                    value={Features.id}
                    name={Features.name}
                    onChange={(e) => selectTransmissionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Transmission
                    </option>
                    {Features.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === transmission}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="odometer"
                    className="text-sm font-medium text-default-900"
                  >
                    Odometer *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="odometer"
                    placeholder="This is a search placeholder"
                    value={typeList.id}
                    name={typeList.name}
                    onChange={(e) => selectOdometerChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Odometer
                    </option>
                    {typeList.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === odometer}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="accidental"
                    className="text-sm font-medium text-default-900"
                  >
                    Accidental *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="accidental"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectAccidentalChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Accidental
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === accidental}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="otherRepair"
                    className="text-sm font-medium text-default-900"
                  >
                    Other Repair *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="otherRepair"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectotherRepairlChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Other Repair
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === otherRepair}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fitnessExpiry"
                    className="text-sm font-medium text-default-900"
                  >
                    Fitness Expiry *
                  </label>
                  <input
                    type="Date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fitnessExpiry"
                    placeholder="Enter FitnessExpiry"
                    value={fitnessExpiry}
                    onChange={(e) => setFitnessExpiry(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="roadtaxValidity"
                    className="text-sm font-medium text-default-900"
                  >
                    Roadtax Validity *
                  </label>
                  <input
                    type="Date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="roadtaxValidity"
                    placeholder="Enter RoadtaxValidity"
                    value={roadtaxValidity}
                    onChange={(e) => setroadtaxValidity(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fIdvValue"
                    className="text-sm font-medium text-default-900"
                  >
                    Idv Value *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fIdvValue"
                    placeholder="Enter RoadtaxValidity"
                    value={idvValue}
                    onChange={(e) => setIdvValue(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fInsuranceExpiry"
                    className="text-sm font-medium text-default-900"
                  >
                    Insurance Expiry *
                  </label>
                  <input
                    type="date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fInsuranceExpiry"
                    placeholder="Enter Insurance Expiry"
                    value={insuranceExpiry}
                    onChange={(e) => setInsuranceExpiry(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="engineCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      engineConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="engineCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectEngineConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Engine Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === engineCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="wheelType"
                    className="text-sm font-medium text-default-900"
                  >
                    Wheel Type *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="wheelType"
                    placeholder="This is a search placeholder"
                    value={wheelTypeList.id}
                    name={wheelTypeList.name}
                    onChange={(e) => selectWheelTypeChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Wheel Type
                    </option>
                    {wheelTypeList.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === wheelType}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="fTotalTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Total Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fTotalTyre"
                    placeholder="Enter Total Tyre"
                    value={totalTyre}
                    onChange={(e) => setTotalTyre(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fTotalTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Available Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fTotalTyre"
                    placeholder="Enter Available Tyre"
                    value={availableTyre}
                    onChange={(e) => setAvailableTyre(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fMissingTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Missing Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fMissingTyre"
                    placeholder="Enter Missing Tyre"
                    value={missingTyre}
                    onChange={(e) => setMissingTyre(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fSpareTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Spare Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fSpareTyre"
                    placeholder="Enter Spare Tyre"
                    value={spareTyre}
                    onChange={(e) => setSpareTyre(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="interiorCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Interior Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      interiorConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="interiorCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectInteriorConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Interior Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === interiorCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="exteriorCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Exterior Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      exteriorConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="exteriorCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectExteriorConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Exterior Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === exteriorCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="headLamp"
                    className="text-sm font-medium text-default-900"
                  >
                    Head Lamp *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="headLamp"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectHeadLampChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Head Lamp
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === headLamp}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="tailLamp"
                    className="text-sm font-medium text-default-900"
                  >
                    Tail Lamp *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="tailLamp"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectTailLampChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Tail Lamp
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === tailLamp}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontIndicators"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Indicators *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="frontIndicators"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectFrontIndicatorsChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Front Indicators
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === frontIndicators}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearIndicators"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Indicators *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="rearIndicators"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectRearIndicatorsChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Rear Indicators
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === rearIndicators}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="maintenanceLevel"
                    className="text-sm font-medium text-default-900"
                  >
                    Maintenance Level *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="maintenanceLevel"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectMaintenanceLevelChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Maintenance Level
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === maintenanceLevel}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontBrakeCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    FrontBreak Condition *
                  </label>
                  <select
                    className={
                      frontBrakeConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="frontBrakeCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectFrontBreakConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose FrontBreak Condition <span style={{ color: 'red' }}>**</span>
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === frontBrakeCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearBrakeCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Break Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      rearBrakeConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="rearBrakeCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectRearBreakConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Rear Break Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === rearBrakeCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comFrontCrashGuard"
                    className="text-sm font-medium text-default-900"
                  >
                    Front CrashGuard *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comFrontCrashGuard"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectsetFrontCrashGuardChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Front CrashGuard
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comFrontCrashGuard}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comLoadCarrier"
                    className="text-sm font-medium text-default-900"
                  >
                    Load Carrier *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comLoadCarrier"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectComLoadCarrierChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Load Carrier
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comLoadCarrier}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comMusicSystem"
                    className="text-sm font-medium text-default-900"
                  >
                    Music System *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comMusicSystem"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectComMusicSystemChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Music System
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comMusicSystem}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comRearUnderRun"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear UnderRun *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comRearUnderRun"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectComRearUnderRunChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Rear UnderRun
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comRearUnderRun}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comSideUnderRun"
                    className="text-sm font-medium text-default-900"
                  >
                    Side UnderRun *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comSideUnderRun"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectComSideUnderRunChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Side UnderRun
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comSideUnderRun}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comToolkit"
                    className="text-sm font-medium text-default-900"
                  >
                    Toolkit *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comToolkit"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectComToolkitChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Toolkit
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comToolkit}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comTvVideo"
                    className="text-sm font-medium text-default-900"
                  >
                    Tv & Video  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comTvVideo"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectComTvVideoChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Tv & Video
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comTvVideo}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="comBodyCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Body Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comBodyCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectComBodyConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Body Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comBodyCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comHandBrake"
                    className="text-sm font-medium text-default-900"
                  >
                    Hand Brake *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comHandBrake"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectComHandBrakeChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Hand Brake
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comHandBrake}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comDoorCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Door Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comDoorCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectComDoorConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose  Door Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comDoorCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comSideGlassesCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Side Glasses Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comSideGlassesCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectComSideGlassesConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose  Side Glasses Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comSideGlassesCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comWindscreenCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Wind Screen Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comWindscreenCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectComWindscreenConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Wind Screen Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comWindscreenCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comBatteryCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Battery Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comBatteryCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectComBatteryConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Battery Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comBatteryCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comClusterUnitCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Cluster Unit Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comClusterUnitCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectComClusterUnitConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose  Cluster Unit Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comClusterUnitCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comFogLampStatus"
                    className="text-sm font-medium text-default-900"
                  >
                    FogLamp Status *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comFogLampStatus"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectComFogLampStatusChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose  FogLamp Status
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comFogLampStatus}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="fBatteryNo"
                    className="text-sm font-medium text-default-900"
                  >
                    Battery No *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fBatteryNo"
                    placeholder="Enter Battery No"
                    value={comBatteryNo}
                    onChange={(e) => setComBatteryNo(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comWiperStatus"
                    className="text-sm font-medium text-default-900"
                  >
                    Wiper Status *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comWiperStatus"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectComWiperStatusChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose  Wiper Status
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comWiperStatus}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comEngineStatus"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine Status *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comEngineStatus"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectComEngineStatusChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose  Engine Status
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comEngineStatus}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comEngineShieldCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine Shield Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comEngineShieldCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectComEngineShieldConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Engine Shield Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comEngineShieldCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comFluidLeakStatus"
                    className="text-sm font-medium text-default-900"
                  >
                    Fluid Leak Status *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comFluidLeakStatus"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectComFluidLeakStatusChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Fluid Leak Status
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comFluidLeakStatus}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comSuspensionCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Suspension Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      comSuspensionConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="comSuspensionCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectComSuspensionConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Suspension Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comSuspensionCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comTransmissionCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Transmission Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      comTransmissionConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="comTransmissionCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectComTransmissionConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Transmission Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comTransmissionCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="comAxleOneLeftIn"
                    className="text-sm font-medium text-default-900"
                  >
                    Axle OneLeft In  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comAxleOneLeftIn"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComAxleOneLeftInChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Axle OneLeft In
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comAxleOneLeftIn}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comAxleOneLeftOut"
                    className="text-sm font-medium text-default-900"
                  >
                    Axle OneLeft Out  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comAxleOneLeftOut"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComAxleOneLeftOutChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Axle OneLeft Out
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comAxleOneLeftOut}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comAxleOneRightIn"
                    className="text-sm font-medium text-default-900"
                  >
                    Axle OneRight In  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comAxleOneRightIn"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComAxleOneRightInChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Axle OneRight In
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comAxleOneRightIn}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comAxleOneRightOut"
                    className="text-sm font-medium text-default-900"
                  >
                    Axle OneRight Out  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comAxleOneRightOut"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComAxleOneRightOutChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Axle OneRight Out
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comAxleOneRightOut}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comAxleThreeLeftIn"
                    className="text-sm font-medium text-default-900"
                  >
                    Axle ThreeLeft In  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comAxleThreeLeftIn"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComAxleThreeLeftInChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Axle ThreeLeft In
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comAxleThreeLeftIn}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comAxleThreeLeftOut"
                    className="text-sm font-medium text-default-900"
                  >
                    Axle ThreeLeft Out  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comAxleThreeLeftOut"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComAxleThreeLeftOutChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Axle ThreeLeft Out
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comAxleThreeLeftOut}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comAxleThreeRightIn"
                    className="text-sm font-medium text-default-900"
                  >
                    Axle ThreeRight In  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comAxleThreeRightIn"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComAxleThreeRightInChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Axle ThreeRight In
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comAxleThreeRightIn}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comAxleThreeRightOut"
                    className="text-sm font-medium text-default-900"
                  >
                    Axle ThreeRight Out  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comAxleThreeRightOut"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComAxleThreeRightOutChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Axle ThreeRight Out
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comAxleThreeRightOut}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comAxleTwoLeftIn"
                    className="text-sm font-medium text-default-900"
                  >
                    Axle TwoLeft In  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comAxleTwoLeftIn"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComAxleTwoLeftInChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Axle TwoLeft In
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comAxleTwoLeftIn}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comAxleTwoLeftOut"
                    className="text-sm font-medium text-default-900"
                  >
                    Axle TwoLeft Out  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comAxleTwoLeftOut"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComAxleTwoLeftOutChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Axle TwoLeft Out
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comAxleTwoLeftOut}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comAxleTwoRightIn"
                    className="text-sm font-medium text-default-900"
                  >
                    Axle TwoRight In  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comAxleTwoRightIn"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComAxleTwoRightInChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Axle TwoRight In
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comAxleTwoRightIn}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comAxleTwoRightOut"
                    className="text-sm font-medium text-default-900"
                  >
                    Axle TwoRight Out  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comAxleTwoRightOut"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComAxleTwoRightOutChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Axle TwoRight Out
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comAxleTwoRightOut}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comFrontOneLeft"
                    className="text-sm font-medium text-default-900"
                  >
                    Front OneLeft  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comFrontOneLeft"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComFrontOneLeftChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Front OneLeft
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comFrontOneLeft}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comFrontOneRight"
                    className="text-sm font-medium text-default-900"
                  >
                    Front OneRight  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comFrontOneRight"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComFrontOneRightChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Front OneRight
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comFrontOneRight}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comFrontTwoLeft"
                    className="text-sm font-medium text-default-900"
                  >
                    Front TwoLeft  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comFrontTwoLeft"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComFrontTwoLeftChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Front TwoLeft
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comFrontTwoLeft}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comFrontTwoRight"
                    className="text-sm font-medium text-default-900"
                  >
                    Front TwoRight  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comFrontTwoRight"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectComFrontTwoRightChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Front TwoRight
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comFrontTwoRight}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comFrontTruckTyreCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Front TruckTyre Condition  <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      comFrontTruckTyreConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="comFrontTruckTyreCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectComFrontTruckTyreConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Front TruckTyre Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comFrontTruckTyreCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comFrontTruckTyreType"
                    className="text-sm font-medium text-default-900"
                  >
                    Front TruckTyre Type  *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fitnessExpiry"
                    placeholder="Enter Tyre Type"
                    value={comFrontTruckTyreType}
                    onChange={(e) => setComFrontTruckTyreType(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="comAxleCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Axle Condition  *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="comAxleCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectComAxleConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Axle Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === comAxleCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fExShowRoomPrice"
                    className="text-sm font-medium text-default-900"
                  >
                    Your Remark On Vehicle *
                  </label>
                  <input
                    type="text"
                    className={
                      `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                    }
                    id="fExShowRoomPrice"
                    placeholder="Enter your remark"
                    value={yourRemark}
                    onChange={(e) => setYourRemark(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fExShowRoomPrice"
                    className="text-sm font-medium text-default-900"
                  >
                    Ex-ShowRoom Price <span style={{ color: 'red' }}>**</span>
                  </label>
                  <input
                    type="text"
                    className={
                      exShowRoomPriceError === ""
                        ? `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                        : `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class`
                    }
                    id="fExShowRoomPrice"
                    placeholder="Enter Ex-ShowRoom Price"
                    value={exShowRoomPrice}
                    onChange={(e) => setExShowRoomPrice(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fEstimatedPrice"
                    className="text-sm font-medium text-default-900"
                  >
                    Estimated Price <span style={{ color: 'red' }}>**</span>
                  </label>
                  <input
                    type="text"
                    className={
                      estimatedPriceError === ""
                        ? `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                        : `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class`
                    }
                    id="fEstimatedPrice"
                    placeholder="Enter Estimated Price"
                    value={estimatedPrice}
                    onChange={(e) => setEstimatedPrice(e.target.value)}
                  />
                  {
                    depreciatedPrice ?
                      <div>The recommended estimated price for the vehicle is <span style={{ color: 'red' }}>₹{depreciatedPrice}</span></div> : <></>
                  }
                </div>
              </div>
              <div
                className="flex items-center gap-2 justify-end"
                style={{ marginBottom: "80px" }}
              >
                <button
                  style={{
                    background: "#ef4444",
                    color: "#fff",
                    border: "none",
                    outline: "none",
                  }}
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border border-transparent align-middle duration-500 text-sm text-center text-red-600 hover:border-red-500/20 hover:bg-red-500/20 rounded transition-all"
                  onClick={() => navigation(`/admin/editreport3/${params.id}`)}
                >
                  <i className="ph-bold ph-x text-lg" />
                  Go Back
                </button>
                <button
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                  style={{
                    background: "#4D44B5",
                    border: "none",
                    outline: "none",
                  }}
                  onClick={() => handleSubmit()}
                >
                  <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                  View Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComEditReport;
