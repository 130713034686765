import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import Home from "./pages/Home";
import { useEffect } from "react";
import Login from "./pages/login/Login";
import { useDispatch } from "react-redux";
import AddAdmin from "./pages/admin-magangement/AddEmployee";
import ListAdmin from "./pages/admin-magangement/EmployeeList";
import SubmittedCase from "./pages/cases/SubmittedCase";
import AssignedCase from "./pages/cases/AssignedCase";
import PendingCase from "./pages/cases/PendingCase";
import { getAllEmployee } from "./redux/Employee/employeeSlice";
import AddEmployee from "./pages/admin-magangement/AddEmployee";
import EmployeeList from "./pages/admin-magangement/EmployeeList";
import UpdateEmployee from "./pages/admin-magangement/UpdateEmployee";
import AddClient from "./pages/admin-magangement/AddClient";
import BackendList from "./pages/cases/BackendList";
import CreateCase from "./pages/cases/CreateCase";
import { getAllCases } from "./redux/Cases/CaseSlice";
import ReleaseReport from "./pages/Reports/ReleaseReport";
import UpdateCase from "./pages/cases/UpdateCase";
import BrandLogo from "./pages/Logo/BrandLogo";
import OrganisationLogo from "./pages/Logo/OrganisationLogo";
import { getLogo } from "./redux/Logo/BrandLogoSlice";
import { getOrgLogo } from "./redux/Logo/OrgLogoSlice";
import { getCarModels } from "./redux/Authentication/AuthSlice";
import ClientList from "./pages/admin-magangement/ClientList";
import Notification from "./pages/notification/Notification";
import { getAllNotification } from "./redux/Notifications/NotificationSlice";
import PrivecyPolicy from "./pages/PrivecyPolicy";
import AddCase from "./pages/cases/AddFourWheelerCase";
import EditReport from "./pages/Reports/EditReport";
import EditReport2 from "./pages/Reports/EditReport2";
import EditReport3 from "./pages/Reports/EditReport3";
import EditReport4 from "./pages/Reports/TwoWheelerEditReport4";
import NewReleaseReport from "./pages/Reports/NewReleaseReport";
import TwoWheelerReport from "./pages/Reports/TwoWheelerReport";
import ConstructionReport from "./pages/Reports/ConstructionReport";
import CommercialReport from "./pages/Reports/CommercialReport";
import TwoWheelerEditReport4 from "./pages/Reports/TwoWheelerEditReport4";
import FourWheelerEditReport4 from "./pages/Reports/FourWheelerEditReport4";
import ConEqpEditReport4 from "./pages/Reports/ConEqpEditReport4";
import ReadyReport from "./pages/cases/ReadyReport";
import ComEditReport from "./pages/Reports/ComEditReport";
import ThreeWheelerEditReport4 from "./pages/Reports/ThreeWheelerEditReport4";
import ThreeWheelerReport from "./pages/Reports/ThreeWheelerReport";
import FarmEquipmentEditReport4 from "./pages/Reports/FarmEquipmentEditReport4";
import FarmEquipmentReport from "./pages/Reports/FarmEquipmentReport";
import TodaySubmitCase from "./pages/MisReport/TodaySubmitCase";
import TodayReadyReport from "./pages/MisReport/TodayReadyReport";
import MonthlyReadyReport from "./pages/MisReport/MonthlyReadyReport";
import MonthlySubmittedCase from "./pages/MisReport/MonthlySubmittedCase";
import MonthlyAssignedCase from "./pages/MisReport/MonthlyAssignedCase";
import MonthlyCasePending from "./pages/MisReport/MonthlyCasePending";
import ImageReport from "./pages/Reports/ImageReport";
import ClientPage from "./pages/Client/ClientPage";
import ClientDashboard from "./pages/Client/ClientDashboard";
import GetAllCase from "./pages/MisReport/GetAllCase";
import ChooseCase from "./pages/cases/ChooseCase";
import AddFourWheelerCase from "./pages/cases/AddFourWheelerCase";
import AddTwoWheelerCase from "./pages/cases/AddTwoWheelerCase";
import AddCommercialCase from "./pages/cases/AddCommercialCase";
import AddConstructionWheelerCase from "./pages/cases/AddConstructionCase";
import AddThreeWheelerCase from "./pages/cases/AddThreeWheelerCase";
import AddFarmEquipmentCase from "./pages/cases/AddFarmEquipment";
import VehicleInfo from "./pages/Reports/VehicleInfo";
import { allClient } from "./redux/ClientAuthentication/ClientSlice";
import UpdateClient from "./pages/admin-magangement/UpdateClient";
import ClientRevenue from "./pages/admin-magangement/ClientRevenue";
import ImageUpload from "./pages/cases/UploadImage";
import CaseImages from "./pages/Reports/CaseImages";
import IntransitSubmitCase from "./pages/cases/IntransitSubmitCase";
import UploadPhotos from "./pages/cases/UploadPhotos";
import BusinessAssociateCase from "./pages/cases/BusinessAssociateCases";

function ScrollToTop() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const initialSetup = async () => {
      try {
        dispatch(getAllEmployee());
        dispatch(allClient());
        dispatch(getAllCases());
        dispatch(getLogo());
        dispatch(getOrgLogo());
        dispatch(getCarModels());
        dispatch(getAllNotification());
      } catch (error) {}
    };
    initialSetup();
  }, []);

  return null;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/ImageUpload" element={<ImageUpload />} />
          <Route path="/clientpage" element={<ClientPage />} />
          <Route path="/clientdashboard" element={<ClientDashboard />} />
          <Route path="/admin" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/admin/clientrevenue" element={<ClientRevenue />} />
            <Route path="/admin/addemployee" element={<AddEmployee />} />
            <Route path="/admin/addclient" element={<AddClient />} />
            <Route path="/admin/updatelient/:_id" element={<UpdateClient />} />
            <Route path="/admin/clients/clientlist" element={<ClientList />} />
            <Route
              path="/admin/employees/listemployees"
              element={<EmployeeList />}
            />
            <Route
              path="/admin/updateemployee/:_id"
              element={<UpdateEmployee />}
            />
            <Route path="/admin/notifications" element={<Notification />} />
            <Route path="/admin/choosecase" element={<ChooseCase />} />
            <Route
              path="/admin/addfourwheelercase"
              element={<AddFourWheelerCase />}
            />
            <Route
              path="/admin/addtwowheelercase"
              element={<AddTwoWheelerCase />}
            />
            <Route
              path="/admin/addcommercialcase"
              element={<AddCommercialCase />}
            />
            <Route
              path="/admin/addconstructioncase"
              element={<AddConstructionWheelerCase />}
            />
            <Route
              path="/admin/addthreewheelercase"
              element={<AddThreeWheelerCase />}
            />
            <Route
              path="/admin/addfarmequipmentcase"
              element={<AddFarmEquipmentCase />}
            />
            <Route path="/admin/createcases" element={<CreateCase />} />
            <Route path="/admin/submittedcases" element={<SubmittedCase />} />
            <Route
              path="/admin/todaysubmittedcases"
              element={<TodaySubmitCase />}
            />
            <Route
              path="/admin/monthlysubmittedcase"
              element={<MonthlySubmittedCase />}
            />
            <Route path="/admin/getallcases" element={<GetAllCase />} />
            <Route path="/admin/readyreport" element={<ReadyReport />} />
            <Route
              path="/admin/todayreadyreport"
              element={<TodayReadyReport />}
            />
            <Route
              path="/admin/monthlyreadyreport"
              element={<MonthlyReadyReport />}
            />
            <Route path="/admin/editreport/:id" element={<EditReport />} />
            <Route path="/admin/editreport2/:id" element={<EditReport2 />} />
            <Route path="/admin/editreport3/:id" element={<EditReport3 />} />
            <Route
              path="/admin/twowheelereditreport4/:id"
              element={<TwoWheelerEditReport4 />}
            />
            <Route
              path="/admin/fourwheelereditreport4/:id"
              element={<FourWheelerEditReport4 />}
            />
            <Route
              path="/admin/coneqpeditreport4/:id"
              element={<ConEqpEditReport4 />}
            />
            <Route
              path="/admin/comeditreport4/:id"
              element={<ComEditReport />}
            />
            <Route
              path="/admin/threewheelereditreport4/:id"
              element={<ThreeWheelerEditReport4 />}
            />
            <Route
              path="/admin/farmequipmenteditreport4/:id"
              element={<FarmEquipmentEditReport4 />}
            />
            {/* <Route
              path="/admin/assignemployee/:_id"
              element={<BackendList/>}
            /> */}
            <Route path="/admin/assignedcases" element={<AssignedCase />} />
            <Route path="/admin/businessassociatecases" element={<BusinessAssociateCase />} />
            <Route
              path="/admin/monthlyassignedcase"
              element={<MonthlyAssignedCase />}
            />
            <Route
              path="/admin/intransitSubmitCases"
              element={<IntransitSubmitCase />}
            />
            <Route path="/admin/pendingcases" element={<PendingCase />} />
            <Route path="/admin/uploadphotos/:id" element={<UploadPhotos />} />
            <Route
              path="/admin/monthlycasepending"
              element={<MonthlyCasePending />}
            />
            <Route path="/admin/updatecase/:_id" element={<UpdateCase />} />

            <Route path="/admin/brandlogo" element={<BrandLogo />} />
            <Route
              path="/admin/organisationlogo"
              element={<OrganisationLogo />}
            />
          </Route>
          {/* TO View Case Images */}
          <Route path="/caseimages" element={<CaseImages />} />
          {/* TO View Case Images */}
          <Route path="/vehicleinfo" element={<VehicleInfo />} />
          <Route path="/releasereport/:caseId" element={<NewReleaseReport />} />
          <Route
            path="/twowheelerreportrelease/:caseId"
            element={<TwoWheelerReport />}
          />
          <Route
            path="/constructionreportrelease/:caseId"
            element={<ConstructionReport />}
          />
          <Route
            path="/commercialreportrelease/:caseId"
            element={<CommercialReport />}
          />
          <Route
            path="/threewheelerreportrelease/:caseId"
            element={<ThreeWheelerReport />}
          />
          <Route
            path="/firmequipmentreportrelease/:caseId"
            element={<FarmEquipmentReport />}
          />
          <Route path="/imagereport/:caseId" element={<ImageReport />} />
          <Route path="/privecy-policy" element={<PrivecyPolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
