import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { CSVLink } from "react-csv";
import { Button } from "jodit/esm/modules";

const TodaySubmitCase = () => {
    const { todaySubmittedCases } = useSelector((store) => store.cases);
    const { loginData } = useSelector((store) => store.auth);
    const [loadData, setLoadData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
            setFilteredData(todaySubmittedCases)
    }, [todaySubmittedCases])


    useEffect(() => {
        let finalexport = [];
        let formdata = {};
        for (let index = 0; index < filteredData.length; index++) {

            const currentDate = new Date(filteredData[index].submittedAt);
            const createdDate = new Date(filteredData[index].createdAt);

            const element = filteredData[index];
            formdata = {
                "No": index + 1,
                "Case Id": element.caseId,
                "Name": element.name,
                "Mobile": element.mobile,
                "Email": element.email,
                "Client Name": element.requesterName,
                "Client Branch": element.requesterBranch,
                "Client Phone": element.requesterPhone,
                "hypothecationBank": element.hypothecationBank,
                "Case Created Date": createdDate.toDateString(),
                "Case Created Time": createdDate.toLocaleTimeString(),
                "Field Service By": element.fieldServiceBy,
                "Field Service Done Date": currentDate.toDateString(),
                "Field Service Done Time": currentDate.toLocaleTimeString(),
                "Asset Type": element.assetType,
                "Brand": element.brand,
                "Model": element.model,
                "Variant": element.variant,
                "RegNo": element.regdNo,
            }
            finalexport = [...finalexport, formdata];
        }
        setLoadData([...finalexport]);
    }, [filteredData])

    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full" style={{ marginBottom: '60px' }}>
                <div className="p-3 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">
                            Dashboard
                        </h4>
                        <div className="p-1">
                            <CSVLink
                                data={loadData}
                                filename={"instavaluer-submit-data.csv"}
                                onClick={() => { }}
                            >
                                <button
                                    className="mt-0 btn btn-primary mb-3 download-responses-button"
                                    style={{
                                        background: "#3270fc",
                                        border: "1px solid #3270fc",
                                        padding: "12px",
                                        color: "#fff",
                                        borderRadius: "3px",
                                    }}
                                >
                                    Download Report
                                </button>
                            </CSVLink>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50">
                        <div className="border-t border-dashed border-default-200">
                            <div className="relative overflow-x-auto">
                                <table className="min-w-full overflow-x-hidden">
                                    <thead className="border-b border-dashed border-default-200">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                No
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                Reg No.
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                Requester Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                Requester Branch
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Mobile
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Email
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Type
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Hypothecation Bank
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Vehicle Preview
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Signature
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-dashed divide-default-200">
                                        {filteredData &&
                                            filteredData.map((admin, index) => (
                                                <tr key={index}>
                                                    <td className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap">
                                                        <b>{admin.caseId}</b>
                                                    </td>
                                                    <td className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap">
                                                        {admin.regdNo}
                                                    </td>
                                                    <td className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap">
                                                        {admin.requesterName}
                                                    </td>
                                                    <td className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap">
                                                        {admin.requesterBranch}
                                                    </td>
                                                    <td className="px-6 py-3 text-center whitespace-nowrap">
                                                        <h6 className="text-sm font-semibold text-default-700">
                                                            {admin.name.length > 30 ? (
                                                                <>{admin.name.slice(0, 30)}...</>
                                                            ) : (
                                                                <>{admin.name.slice(0, 30)}</>
                                                            )}
                                                        </h6>
                                                    </td>
                                                    <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap">
                                                        {admin.mobile}
                                                    </td>
                                                    <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap">
                                                        {admin.email.length > 18 ? (
                                                            <>{admin.email.slice(0, 18)}...</>
                                                        ) : (
                                                            <>{admin.email.slice(0, 18)}</>
                                                        )}
                                                    </td>
                                                    <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap">
                                                        <div className="d-flex m-auto">
                                                            {admin.assetType}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap">
                                                        <div className="d-flex m-auto">
                                                            {admin.hypothecationBank}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-3 text-default-600 font-medium whitespace-nowrap">
                                                        <div className="d-flex m-auto">
                                                            <img
                                                                src={admin.images.frontImage}
                                                                style={{ height: "50px", width: "80px" }}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-3 text-default-600 font-medium whitespace-nowrap">
                                                        <div className="d-flex m-auto">
                                                            <img
                                                                src={admin.signNdoc.sign}
                                                                style={{ height: "50px", width: "80px" }}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center justify-between gap-6 py-3 px-6 border-t border-dashed border-default-200">
                            {/* <h6 className="text-default-600">Showing 1 to 5 of 12</h6> */}
                            {/* <nav className="flex items-center gap-1">
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    <i className="ph ph-caret-left text-base" />
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border rounded-md transition-all duration-200 bg-primary text-white border-primary"
                                    to="#"
                                    aria-current="page"
                                >
                                    1
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    2
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    ...
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    12
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    <i className="ph ph-caret-right text-base" />
                                </a>
                            </nav> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TodaySubmitCase;
