import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
const initialState = {
  isLoading: false,
  caseList: [],
  submittedCases: [],
  todaySubmittedCases: JSON.parse(localStorage.getItem("todaySubmittedCases"))
    ? JSON.parse(localStorage.getItem("todaySubmittedCases"))
    : [],
  caseCreated: [],
  caseAssigned: [],
  pendingCases: [],
  readyReport: [],
  assignedToAssociateCases: [],
  todayReadyReport: JSON.parse(localStorage.getItem("todayReadyReport"))
    ? JSON.parse(localStorage.getItem("todayReadyReport"))
    : [],
  currentCase: "",
  individualCase: "",
  isSelfieWithAgentLoading: true,
  selfieWithVehicleImage: "",
  isChasisImageLoading: true,
  chasisImage: "",
  IsformatImageLoading: true,
  formatImage: "",
  openBonnet: "",
  isOpenBonnetLoading: true,
  right45image: "",
  isRight45ImageLoading: true,
  frontimage: "",
  isFrontImageLoading: true,
  frontUnderCarImage: "",
  isFrontUnderCarImageLoading: true,
  left45image: "",
  isLeft45Loading: true,
  leftImage: "",
  isLeftImageLoading: true,
  rearImage: "",
  isRearImageLoading: true,
  rearUnderPartImage: "",
  isRearUnderPartLoading: true,
  openDickeyImage: "",
  isOpenDickeyImageLoading: true,
  rightImage: "",
  isRightImageLoading: true,
  tyreImage: "",
  isTyreImageLoading: true,
  glassOutsideImage: "",
  isGlassOutsideImageLoading: true,
  glassInsideImage: "",
  isGlassInsideImageLoading: true,
  odometerImage: "",
  isOdometerImageLoading: true,
  rpmImage: "",
  isRpmImageLoading: true,
  anyDamage: "",
  isAnyDamageLoading: true,
  extraImage: "",
  isExtraImageLoading: true,
  extraImage1: "",
  isExtraImage1Loading: true,
  extraImage2: "",
  isExtraImage2Loading: true,
  extraImage3: "",
  isExtraImage3Loading: true,
  signatureImage: "",
  isSignatureImageLoading: true,
  rcFrontImage: "",
  isRcFrontImageLoading: true,
  rcBackImage: "",
  isRcBackImageLoading: true,
  vehicleInfo: JSON.parse(localStorage.getItem("vehicleInfo"))
  ? JSON.parse(localStorage.getItem("vehicleInfo"))
  : [],
  caseImages: JSON.parse(localStorage.getItem("caseImages"))
  ? JSON.parse(localStorage.getItem("caseImages"))
  : "",
};
export const createCase = createAsyncThunk(
  "case/createCase",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      console.log("1");
      const url = `${Baseurl}/api/v1/cases/new`;
      console.log("2");
      const resp = await axios.post(url, formData, config);
      console.log("3");
      console.log(resp.data,"resp.data");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Case not able to create");
    }
  }
);
export const sendVehicleNumber = createAsyncThunk(
  "case/createCase",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        Authorization: "t0itN3Np4tAJZjd7wPKlHvMpWWKj1fiU",
      };
      const url = `${Baseurl}/api/v1/cases/sendvehiclenumber`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Case not able to create");
    }
  }
);
export const getAllCases = createAsyncThunk(
  "case/getAllCases",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/cases/all`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Cases not able to fetch");
    }
  }
);
export const validateMobile = createAsyncThunk(
  "auth/validateNumber",
  async (number, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/cases/mobile/${number}`;
      const resp = await axios.get(url);
      console.log(resp.data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Mobile dosen't exist");
    }
  }
);
export const validateEmail = createAsyncThunk(
  "auth/valiadteEmail",
  async (email, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/cases/email/${email}`;
      const resp = await axios.get(url);
      console.log(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Email no. dosen't exist");
    }
  }
);
export const getSinglecase = createAsyncThunk(
  "case/getSingleCase",
  async (_id, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/cases/individualcase/${_id}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (errro) {
      return thunkAPI.rejectWithValue("Case not able to fetch");
    }
  }
);
export const updateIndividualCase = createAsyncThunk(
  "case/updateCase",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/cases/updatecase/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Case not able to update");
    }
  }
);
export const selfieWithVehicleUpload = createAsyncThunk(
  "case/selfieWithVehicle",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      console.log(formData, "formData");
      const url = `${Baseurl}/api/v1/cases/uploadselfiewithvehicle`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data, "resp");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const UploadChasisImage = createAsyncThunk(
  "case/chasisimage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadchasisimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadformatImage = createAsyncThunk(
  "case/formatimage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadformatimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const UploadOpenBonnet = createAsyncThunk(
  "case/openbonnet",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadopenbonnet`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const UploadFrontImage = createAsyncThunk(
  "case/uploadfrontimage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadfrontimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const UploadRight45Image = createAsyncThunk(
  "case/right45image",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadright45image`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadFrontUnderCar = createAsyncThunk(
  "case/uploadFrontUnderCar",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadFrontUnderCar`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadLeft45 = createAsyncThunk(
  "case/uploadleft45",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadleft45`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadLeftImage = createAsyncThunk(
  "case/uploadleftimage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadleftimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadRearImage = createAsyncThunk(
  "case/uploadrearimage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadrearimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const UploadRearUnderPart = createAsyncThunk(
  "case/uploadRearUnderPart",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadrearunderpart`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadOpenDickey = createAsyncThunk(
  "case/uploadopendickey",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadopendickey`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadRightImage = createAsyncThunk(
  "case/uploadrightimage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadrightimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadTyreImage = createAsyncThunk(
  "case/uploadtyreimage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadtyreimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadGlassOutside = createAsyncThunk(
  "case/uploadglassoutside",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadglassoutside`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadGlassInsideImage = createAsyncThunk(
  "case/uploadglassinsideimage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadglassinsideimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadOdometerImage = createAsyncThunk(
  "case/uploadodometerimage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadodometerimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadRpmImage = createAsyncThunk(
  "case/uploadrpmimage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadrpmimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadAnyDamage = createAsyncThunk(
  "case/uploadanydamage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadanydamage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadExtraImage = createAsyncThunk(
  "case/extradamage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadextraimage`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data, "resp");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadExtraImage1 = createAsyncThunk(
  "case/extradamage1",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadextraimage1`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadExtraImage2 = createAsyncThunk(
  "case/extradamage2",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadextraimage2`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadExtraImage3 = createAsyncThunk(
  "case/extradamage3",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadextraimage3`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadSignature = createAsyncThunk(
  "case/uploadsignature",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadsignature`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadRcFront = createAsyncThunk(
  "case/uploadrcfront",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadrcfront`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);
export const uploadRcBackImage = createAsyncThunk(
  "case/uploadrcbackimage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/cases/uploadrcbackimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Image not able to upload");
    }
  }
);

const caseSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {
    setCaseImages(state,action){
      state.caseImages = action.payload
      localStorage.setItem("caseImages", JSON.stringify(state.caseImages));
    },
    setVehicleInfo(state, action) {
      console.log(action.payload, "action.payload");
      state.vehicleInfo = action.payload;
      localStorage.setItem("vehicleInfo", JSON.stringify(state.vehicleInfo));
    },
    setTodaySubmittedCases(state, action) {
      state.todaySubmittedCases = action.payload;
      localStorage.setItem(
        "todaySubmittedCases",
        JSON.stringify(state.todaySubmittedCases)
      );
    },
    setTodayReadyReport(state, action) {
      state.todayReadyReport = action.payload;
      localStorage.setItem(
        "todayReadyReport",
        JSON.stringify(state.todayReadyReport)
      );
    },
    setCurrCase(state, action) {
      state.currentCase = action.payload;
    },
    assignAgent(state, action) {
      state.assignedAgent = action.payload;
    },
    afterPost(state) {
      state.selfieWithVehicleImage = "";
      state.chasisImage = "";
      state.formatImage = "";
      state.openBonnet = "";
      state.right45image = "";
      state.frontimage = "";
      state.frontUnderCarImage = "";
      state.left45image = "";
      state.rearImage = "";
      state.rearUnderPartImage = "";
      state.openDickeyImage = "";
      state.rightImage = "";
      state.tyreImage = "";
      state.glassOutsideImage = "";
      state.glassInsideImage = "";
      state.odometerImage = "";
      state.rpmImage = "";
      state.anyDamage = "";
      state.signatureImage = "";
      state.rcFrontImage = "";
      state.rcBackImage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCase.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCase.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.caseList = [action.payload.case, ...state.caseList];
        }
        state.isLoading = false;
      })
      .addCase(createCase.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCases.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCases.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.caseList = action.payload.cases;
          state.caseCreated = action.payload.cases.filter(
            (individualCase) => individualCase.status === 1
          );
          state.caseAssigned = action.payload.cases.filter(
            (individualCase) => individualCase.status === 2
          );
          state.pendingCases = action.payload.cases.filter(
            (individualCase) => individualCase.status === 3
          );
          state.submittedCases = action.payload.cases.filter(
            (individualCase) => individualCase.status === 4
          );
          state.readyReport = action.payload.cases.filter(
            (individualCase) => individualCase.status === 5
          );
          state.assignedToAssociateCases = action.payload.cases.filter(
            (individualCase) => individualCase.status === 6
          );
          state.isLoading = false;
        }
      })
      .addCase(getAllCases.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(updateIndividualCase.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateIndividualCase.fulfilled, (state, action) => {
        if (action.payload.case.status === 1) {
          state.caseCreated = state.caseCreated.filter(
            (individualCase) => individualCase._id === action.payload.case._id
          );
          state.caseCreated = [action.payload.case, ...state.caseCreated];
        }
        if (action.payload.case.status === 2) {
          state.caseCreated = state.caseCreated.filter(
            (individualCase) => individualCase._id === action.payload.case._id
          );
          state.caseAssigned = [action.payload.case, ...state.caseAssigned];
        }
        if (action.payload.case.status === 3) {
          state.caseAssigned = state.caseAssigned.filter(
            (individualCase) => individualCase._id === action.payload.case._id
          );
          state.pendingCases = [action.payload.case, ...state.pendingCases];
        }
        if (action.payload.case.status === 4) {
          state.pendingCases = state.pendingCases.filter(
            (individualCase) => individualCase._id === action.payload.case._id
          );
          state.submittedCases = [action.payload.case, ...state.submittedCases];
        }
        if (action.payload.case.status === 5) {
          state.submittedCases = state.submittedCases.filter(
            (individualCase) => individualCase._id === action.payload.case._id
          );
          state.readyReport = [action.payload.case, ...state.readyReport];
        }
        if (action.payload.case.status === 6) {
          state.readyReport = state.readyReport.filter(
            (individualCase) => individualCase._id === action.payload.case._id
          );
          state.assignedToAssociateCases = [action.payload.case, ...state.assignedToAssociateCases];
        }
        state.isLoading = false;
      })
      .addCase(updateIndividualCase.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(getSinglecase.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSinglecase.fulfilled, (state, action) => {
        state.individualCase = action.payload.case;
        state.isLoading = false;
      })
      .addCase(getSinglecase.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(selfieWithVehicleUpload.pending, (state) => {
        state.isSelfieWithAgentLoading = true;
      })
      .addCase(selfieWithVehicleUpload.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.selfieWithVehicleImage = action.payload.image;
        }
        state.isSelfieWithAgentLoading = false;
      })
      .addCase(selfieWithVehicleUpload.rejected, (state) => {
        state.isSelfieWithAgentLoading = true;
      })
      .addCase(UploadChasisImage.pending, (state) => {
        state.isChasisImageLoading = true;
      })
      .addCase(UploadChasisImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.chasisImage = action.payload.image;
        }
        state.isChasisImageLoading = false;
      })
      .addCase(UploadChasisImage.rejected, (state) => {
        state.isChasisImageLoading = true;
      })
      .addCase(uploadformatImage.pending, (state) => {
        state.IsformatImageLoading = true;
      })
      .addCase(uploadformatImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.formatImage = action.payload.image;
        }
        state.IsformatImageLoading = false;
      })
      .addCase(uploadformatImage.rejected, (state) => {
        state.IsformatImageLoading = true;
      })
      .addCase(UploadOpenBonnet.pending, (state) => {
        state.isOpenBonnetLoading = true;
      })
      .addCase(UploadOpenBonnet.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.openBonnet = action.payload.image;
        }
        state.isOpenBonnetLoading = false;
      })
      .addCase(UploadOpenBonnet.rejected, (state) => {
        state.isOpenBonnetLoading = true;
      })
      .addCase(UploadRight45Image.pending, (state) => {
        state.isRight45ImageLoading = true;
      })
      .addCase(UploadRight45Image.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.right45image = action.payload.image;
        }
        state.isRight45ImageLoading = false;
      })
      .addCase(UploadRight45Image.rejected, (state) => {
        state.isRight45ImageLoading = true;
      })
      .addCase(UploadFrontImage.pending, (state) => {
        state.isFrontImageLoading = true;
      })
      .addCase(UploadFrontImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.frontimage = action.payload.image;
        }
        state.isFrontImageLoading = false;
      })
      .addCase(UploadFrontImage.rejected, (state) => {
        state.isFrontImageLoading = true;
      })
      .addCase(uploadFrontUnderCar.pending, (state) => {
        state.isFrontUnderCarImageLoading = true;
      })
      .addCase(uploadFrontUnderCar.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.frontUnderCarImage = action.payload.image;
        }
        state.isFrontUnderCarImageLoading = false;
      })
      .addCase(uploadFrontUnderCar.rejected, (state) => {
        state.isFrontUnderCarImageLoading = true;
      })
      .addCase(uploadLeft45.pending, (state) => {
        state.isLeft45Loading = true;
      })
      .addCase(uploadLeft45.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.left45image = action.payload.image;
        }
        state.isLeft45Loading = false;
      })
      .addCase(uploadLeft45.rejected, (state) => {
        state.isLeft45Loading = true;
      })
      .addCase(uploadLeftImage.pending, (state) => {
        state.isLeftImageLoading = true;
      })
      .addCase(uploadLeftImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.leftImage = action.payload.image;
        }
        state.isLeftImageLoading = false;
      })
      .addCase(uploadLeftImage.rejected, (state) => {
        state.isLeftImageLoading = true;
      })
      .addCase(uploadRearImage.pending, (state) => {
        state.isRearImageLoading = true;
      })
      .addCase(uploadRearImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.rearImage = action.payload.image;
        }
        state.isRearImageLoading = false;
      })
      .addCase(uploadRearImage.rejected, (state) => {
        state.isRearImageLoading = true;
      })
      .addCase(UploadRearUnderPart.pending, (state) => {
        state.isRearUnderPartLoading = true;
      })
      .addCase(UploadRearUnderPart.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.rearUnderPartImage = action.payload.image;
        }
        state.isRearUnderPartLoading = false;
      })
      .addCase(UploadRearUnderPart.rejected, (state) => {
        state.isRearUnderPartLoading = true;
      })
      .addCase(uploadOpenDickey.pending, (state) => {
        state.isOpenDickeyImageLoading = true;
      })
      .addCase(uploadOpenDickey.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.openDickeyImage = action.payload.image;
        }
        state.isOpenDickeyImageLoading = false;
      })
      .addCase(uploadOpenDickey.rejected, (state) => {
        state.isOpenDickeyImageLoading = true;
      })
      .addCase(uploadRightImage.pending, (state) => {
        state.isRightImageLoading = true;
      })
      .addCase(uploadRightImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.rightImage = action.payload.image;
        }
        state.isRightImageLoading = false;
      })
      .addCase(uploadRightImage.rejected, (state) => {
        state.isRightImageLoading = true;
      })
      .addCase(uploadTyreImage.pending, (state) => {
        state.isTyreImageLoading = true;
      })
      .addCase(uploadTyreImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.tyreImage = action.payload.image;
        }
        state.isTyreImageLoading = false;
      })
      .addCase(uploadTyreImage.rejected, (state) => {
        state.isTyreImageLoading = true;
      })
      .addCase(uploadGlassOutside.pending, (state) => {
        state.isGlassOutsideImageLoading = true;
      })
      .addCase(uploadGlassOutside.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.glassOutsideImage = action.payload.image;
        }
        state.isGlassOutsideImageLoading = false;
      })
      .addCase(uploadGlassOutside.rejected, (state) => {
        state.isGlassOutsideImageLoading = true;
      })
      .addCase(uploadGlassInsideImage.pending, (state) => {
        state.isGlassInsideImageLoading = true;
      })
      .addCase(uploadGlassInsideImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.glassInsideImage = action.payload.image;
        }
        state.isGlassInsideImageLoading = false;
      })
      .addCase(uploadGlassInsideImage.rejected, (state) => {
        state.isGlassInsideImageLoading = true;
      })
      .addCase(uploadOdometerImage.pending, (state) => {
        state.isOdometerImageLoading = true;
      })
      .addCase(uploadOdometerImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.odometerImage = action.payload.image;
        }
        state.isOdometerImageLoading = false;
      })
      .addCase(uploadOdometerImage.rejected, (state) => {
        state.isOdometerImageLoading = true;
      })
      .addCase(uploadRpmImage.pending, (state) => {
        state.isRpmImageLoading = true;
      })
      .addCase(uploadRpmImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.rpmImage = action.payload.image;
        }
        state.isRpmImageLoading = false;
      })
      .addCase(uploadRpmImage.rejected, (state) => {
        state.isRpmImageLoading = true;
      })
      .addCase(uploadAnyDamage.pending, (state) => {
        state.isAnyDamageLoading = true;
      })
      .addCase(uploadAnyDamage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.anyDamage = action.payload.image;
        }
        state.isAnyDamageLoading = false;
      })
      .addCase(uploadAnyDamage.rejected, (state) => {
        state.isAnyDamageLoading = true;
      })
      .addCase(uploadExtraImage.pending, (state) => {
        state.isExtraImageLoading = true;
      })
      .addCase(uploadExtraImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.extraImage = action.payload.image;
        }
        state.isExtraImageLoading = false;
      })
      .addCase(uploadExtraImage.rejected, (state) => {
        state.isExtraImageLoading = true;
      })
      .addCase(uploadExtraImage1.pending, (state) => {
        state.isExtraImage1Loading = true;
      })
      .addCase(uploadExtraImage1.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.extraImage1 = action.payload.image;
        }
        state.isExtraImage1Loading = false;
      })
      .addCase(uploadExtraImage1.rejected, (state) => {
        state.isExtraImage1Loading = true;
      })
      .addCase(uploadExtraImage2.pending, (state) => {
        state.isExtraImage2Loading = true;
      })
      .addCase(uploadExtraImage2.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.extraImage2 = action.payload.image;
        }
        state.isExtraImage2Loading = false;
      })
      .addCase(uploadExtraImage2.rejected, (state) => {
        state.isExtraImage2Loading = true;
      })
      .addCase(uploadExtraImage3.pending, (state) => {
        state.isExtraImage3Loading = true;
      })
      .addCase(uploadExtraImage3.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.extraImage3 = action.payload.image;
        }
        state.isExtraImage3Loading = false;
      })
      .addCase(uploadExtraImage3.rejected, (state) => {
        state.isExtraImage3Loading = true;
      })

      .addCase(uploadSignature.pending, (state) => {
        state.isSignatureImageLoading = true;
      })
      .addCase(uploadSignature.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.signatureImage = action.payload.image;
        }
        state.isSignatureImageLoading = false;
      })
      .addCase(uploadSignature.rejected, (state) => {
        state.isSignatureImageLoading = true;
      })
      .addCase(uploadRcFront.pending, (state) => {
        state.isRcFrontImageLoading = true;
      })
      .addCase(uploadRcFront.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.rcFrontImage = action.payload.image;
        }
        state.isRcFrontImageLoading = false;
      })
      .addCase(uploadRcFront.rejected, (state) => {
        state.isRcFrontImageLoading = true;
      })
      .addCase(uploadRcBackImage.pending, (state) => {
        state.isRcBackImageLoading = true;
      })
      .addCase(uploadRcBackImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.rcBackImage = action.payload.image;
        }
        state.isRcBackImageLoading = false;
      })
      .addCase(uploadRcBackImage.rejected, (state) => {
        state.isRcBackImageLoading = true;
      });
  },
});

export const CaseActions = caseSlice.actions;
export default caseSlice.reducer;
